import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import {TableserviceService} from '../../services/tableservice.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'safe'
})
@Component({
  selector: 'app-incident-button',
  templateUrl: './incident-button.component.html',
  styleUrls: ['./incident-button.component.css']
})
export class IncidentButtonComponent implements OnInit, PipeTransform {
  public iframeURI: any;
  closeResult: string;
  constructor(
    private tableService: TableserviceService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
  ) { }

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  transform(url) {
    // console.log("IN TRANSFORM");
    // console.log(url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
    console.log("Button Rendered");
  }

  open(content) {
    this.iframeURI =  this.transform(this.rowData.iframeURI);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal fade bd-example-modal-xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
