import { Component, OnInit } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../auth/auth.service';
import * as jwt from 'jsonwebtoken'
import * as jwt_decode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import config from '../../../../configuration.json'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  organisation;
  notificationData: object = {};
  incidentTitle: string = '';
  incidentID: number;
  incidentData = [];
  show: boolean = false;
  userDate: any;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  userNotificationData: object = {}
  count: number;
  toggle = true;
  selectedRow: number;
  selected = [];
  showNotificationIcon: boolean = false;
  auth0: any;
  userEmail: any = '';
  user: string;

  constructor(public auth: AuthService, public httpClient: HttpClient,) { }

  ngOnInit() {
    var __this = this;
    this.auth0 = this.auth.userProfile$['source'];
    // console.log(this.auth0);
    return this.auth.getTokenSilently$()
      .toPromise()
      .then(function (res) {
        // console.log("authres");
        console.log(res);
        var decoded = jwt_decode(res);
        __this.organisation = decoded.permissions[0];
        __this.auth.organisation = __this.organisation;
        __this.getUserData();
      })
  }
  async getUserData() {
    let userEmail = await this.userEmailData();
    let userResponse = await this.getNotificationData();
  }
  async userEmailData() {
    let __this = this;
    return __this.auth.getUser$().toPromise().then(function (response) {
      __this.userEmail = response['email'];
    })
  }

  checkSelected(i) {
    return this.selected.indexOf(i) > -1;
  }
  setSelected(i, articleId) {
    this.selected.push(i)
  }
  showNotifications() {
    this.show = !this.show;
    // console.log(this.show);
  }
  getNotificationList(incidentID: any, articleId: any) {

    this.userEmail = this.auth.userProfile$['source']['_value']['email'];
    //DATE DATATYPE FORMAT - YYYY-MM-DD
    let currentDate = new Date();
    let currentDateStr = currentDate.toString();
    let year = currentDateStr.split(' ')[3];
    let month = currentDateStr.split(' ')[1];
    let day = currentDateStr.split(' ')[2];
    if (this.count === 0) {
      this.count === 0
    } else {
      // this.count = this.count - 1;
      this.toggle = !this.toggle;
    }
    this.userDate = currentDate.getFullYear() + '-' + (this.months.indexOf(month) + 1) + '-' + day;
    var __this = this;
    __this.userNotificationData = {
      date: this.userDate,
      incidentId: incidentID,
      email: this.userEmail,
      articleId: articleId,
      userData: this.userEmail + '-' + articleId
    }
    return this.httpClient.post(config.apiUrl + '/uniform/usernotifications', this.userNotificationData)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          // console.log(response);
          this.count = this.incidentData.length - 1;
          // __this.incidentData = response.data;
        }
      })
  }

  getNotificationData() {
    var __this = this;
    __this.notificationData = {
      dates: '2021-05-29',
      userEmail: __this.userEmail
    }
    console.log(this.notificationData);
    return this.httpClient.post(config.apiUrl + '/uniform/notificationincidents', this.notificationData)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          // console.log(response);
          // for(let i = 0; i< response.data.length; i++){
          //   // console.log(response.data[i].title);
          // }
          __this.incidentData = response.data;
          if (__this.incidentData.length > 0) {
            __this.count = __this.incidentData.length;
            __this.showNotificationIcon = true;
          }
        }
      })
  }
}
