<div class="nav-container">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <div class="navbar-brand logo">
        <a routerLink="/" *ngIf="auth.loggedIn === false; else elseBlock" class="pull-left"><img
            src="./../../../assets/blockaviation.png" width="200"> </a>
        <!-- <a routerLink="/dashboard" *ngIf="this.organisation === 'gecas'; else elseBlock" class="pull-left"><img src='{{ "./../../../assets/" + this.organisation + ".png" }}'></a> -->
        <ng-template #elseBlock>
          <a routerLink="/dashboard" class="pull-left" *ngIf="this.organisation !== undefined">
            <img src='{{ "./../../../assets/" + this.organisation + ".png" }}' [ngClass]="{
              'with-width': this.organisation !== 'gecas' && this.organisation !== 'milestoneaviation',
              'full-width': this.organisation === 'gecas' || this.organisation ==='milestoneaviation'
            }" class="mr-4">
          </a>
        </ng-template>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" *ngIf="auth.loggedIn === true">
            <a routerLink="/dashboard" class="nav-link">Dashboard</a>
          </li>
          <li class="nav-item" *ngIf="auth.loggedIn === true">
            <a class="nav-link" routerLink="/monthly">Monthly</a>
          </li>
          <li class="nav-item" *ngIf="auth.loggedIn === true">
            <a class="nav-link" routerLink="/daily">Daily</a>
          </li>
          <li class="nav-item" *ngIf="auth.loggedIn === true">
            <a class="nav-link" routerLink="/insights">Insights</a>
          </li>
          <li class="nav-item" *ngIf="auth.loggedIn === true">
            <a class="nav-link" routerLink="/incidents">Incidents</a></li>
          <li *ngIf="auth.loggedIn === true">
            <div href="javascript:;" type="button" *ngIf="showNotificationIcon === true" (click)="showNotifications()">
              <i class="fa fa-bell-o" aria-hidden="true">
                <span class="num">{{count}}</span></i>
              <ul class="list-group" aria-labelledby="dropdownMenuLink" *ngIf="show">
                <li class="list-group-item" [routerLink]="['/incidents']"
                  *ngFor="let incident of incidentData;let i = index;"
                  (click)="getNotificationList(incident.ldIncident,incident.articleId )">
                  <span class="ddd" [ngClass]="{'selected': checkSelected(i)}"
                    (click)="setSelected(i,incident.articleId)"><b>SN:</b> {{incident.serialNumber}}, <b>REG:</b> {{incident.registration}}, <b>Title:</b> {{incident.title}}</span>
                </li>
              </ul>
              <!-- </div> -->
            </div>
          </li>
        </ul>

        <ul class="navbar-nav d-none d-md-block">
          <!-- Login button: show if NOT authenticated -->
          <li class="nav-item" *ngIf="auth.loggedIn === false">
            <button id="qsLoginBtn" class="btn btn-outline-primary btn-lg rounded-pill" (click)="auth.login()">
              Log in
            </button>
          </li>
          <!-- / Login button -->

          <!-- Fullsize dropdown: show if authenticated -->
          <li class="nav-item dropdown" *ngIf="auth.userProfile$ | async as profile" ngbDropdown>
            <a ngbDropdownToggle class="nav-link dropdown-toggle" id="profileDropDown" data-toggle="dropdown">
              <!-- Profile image should be set to the profile picture from the id token -->
              <img [src]="profile.picture" alt="Profile picture" class="nav-user-profile rounded-circle" height="40"
                width="40" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a href="javascript:;" class="dropdown-item dropdown-profile" *ngIf="profile">
                {{ profile.name }}
              </a>
              <a routerLink="/profile" class="dropdown-item dropdown-profile" *ngIf="profile">
                <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
              </a>
              <button (click)="auth.logout()" class="btn btn-link dropdown-item" id="qsLogoutBtn">
                <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
              </button>
            </div>
          </li>
          <!-- /Fullsize dropdown -->
        </ul>

        <!-- Responsive login button: show if NOT authenticated -->
        <ul class="navbar-nav d-md-none" *ngIf="auth.loggedIn === false">
          <button class="btn btn-outline-primary btn-lg rounded-pill" id="qsLoginBtn" (click)="auth.login()">
            Log in
          </button>
        </ul>
        <!-- /Responsive login button -->

        <!-- Responsive profile dropdown: show if authenticated -->
        <ul class="navbar-nav d-md-none justify-content-between mt-2" *ngIf="auth.userProfile$ | async as profile"
          style="min-height: 110px">
          <li class="nav-item">
            <span class="user-info" *ngIf="profile">
              <!-- Profile image should be set to the profile picture from the id token -->
              <img alt="Profile picture" class="nav-user-profile d-inline-block rounded-circle mr-3" height="40"
                width="40" [src]="profile.picture">
              <!-- Show the user's full name from the id token here -->
              <h6 class="d-inline-block">{{ profile.name }}</h6>
            </span>
          </li>
          <li>
            <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
            <a routerLink="/profile" class="text-grey">Profile</a>
          </li>
          <li>
            <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
            <button class="btn btn-link p-0 text-grey" id="qsLogoutBtn" (click)="auth.logout()">
              Log out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
