import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DailyComponent } from './pages/daily/daily.component';
import { MonthComponent } from './pages/month/month.component';
import { ComparisonComponent } from './pages/comparison/comparison.component';
import { InterceptorService } from './auth/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MonthlyInfoComponent } from './pages/monthly-info/monthly-info.component';
import { DailyInfoComponent } from './pages/daily-info/daily-info.component';
import { InsightComponent } from './pages/insight/insight.component';
import { IncidentComponent } from './pages/incident/incident.component';



const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'monthly',
    component: MonthComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'daily',
    component: DailyComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'insights',
    component: InsightComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'incidents',
    component: IncidentComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'monthlyinfo',
    component: MonthlyInfoComponent
    // canActivate: [AuthGuard]
  },
  {
    path: 'dailyinfo',
    component: DailyInfoComponent
    // canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
