<div class="container mt-5" *ngIf="auth.userProfile$ | async as profile">
  <div class="row align-items-center profile-header mb-5 text-center text-md-left">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <img [src]="profile.picture" class="rounded-circle img-fluid profile-picture mb-3 mb-md-0" />
            </div>
            <div class="col-sm-10">
              <h2 class="text-dark">{{ profile.name }}</h2>
              <p class="lead text-muted">{{ profile.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" *ngIf="profileJson">
    <pre class="rounded"><code class="json" [highlight]="profileJson"></code></pre>
  </div> -->
</div>
