import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as Highstock from "highcharts/highstock";
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Http, Headers, HttpModule,RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import am4themes_dark from "@amcharts/amcharts4/themes/dark"
import * as xlsx from 'xlsx';
import { TableserviceService } from '../../services/tableservice.service';
import { CookieService } from 'ngx-cookie-service';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import config  from '../../../../configuration.json'

@Component({
  selector: 'app-monthly-info',
  templateUrl: './monthly-info.component.html',
  styleUrls: ['./monthly-info.component.css']
})
export class MonthlyInfoComponent implements OnInit {
  @ViewChild('table2') table2: ElementRef;

  dailytotals;
  term1;
  p1;
  p;
  constructor(
  // private httpService :TableserviceService,
  public http: Http,
  public httpClient: HttpClient,
  public tableserviceService: TableserviceService,
  private cookieSerivce: CookieService
) { }


  page: number = 1
  ngOnInit() {
    // this.graphUtilisation()
    // console.log("Cookie");
    // console.log(this.tableserviceService.setNewCookie);

    var cookie =  this.cookieSerivce.get('tableDataCookie')
    // console.log(cookie);

    if(this.tableserviceService.setNewCookie =='true'){
      // console.log("Setting new cookie...");
      this.cookieSerivce.delete('tableDataCookie')
      this.cookieSerivce.set('tableDataCookie',this.tableserviceService.tableDataCookie)
      cookie =  this.cookieSerivce.get('tableDataCookie')
      // console.log("New cookie set");
      // console.log(cookie);
      this.tableserviceService.setNewCookie = 'false'

    }
    else if(cookie != 'undefined'){
      console.log("Cookie present: ");
      console.log(cookie);
      this.tableserviceService.monthlyBreakdown(cookie,'false')
      // this.cookieSerivce.delete('tableDataCookie')
    } else{
      console.log("Cookie not present");
      this.cookieSerivce.set('tableDataCookie',this.tableserviceService.tableDataCookie)
      cookie =  this.cookieSerivce.get('tableDataCookie')
      console.log("Cookie before if");
      console.log(cookie);
    }

  }
  exportToExcel2() {
    // console.log('exporting to xls 2');

   const ws: xlsx.WorkSheet =
   xlsx.utils.table_to_sheet(this.table2.nativeElement);
   const wb: xlsx.WorkBook = xlsx.utils.book_new();
   xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
   xlsx.writeFile(wb, 'monthlyFlightBreakdown.xlsx');
  }
  getClientDetails() {
    // console.log("Getting ClientDetails...");
     var __this = this;
     const headers = new Headers({'Content-Type': 'application/json'});
     const options = new RequestOptions({headers: headers})
     return this.http.get(config.apiUrl +'/uniform/clientdetails/undefined')
     .pipe(
       map(res => res.json()))
     .toPromise()
     .then((crate) => {
     })
  }

  public ngOnDestroy() {
      this.tableserviceService.msn = "";
      this.tableserviceService.reg = "";
      this.tableserviceService.acType = "";
      this.tableserviceService.operator = "";
    }
}
