import { Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import { Observable } from 'rxjs';
import {TableserviceService} from '../../services/tableservice.service';
import { AuthService } from '../../auth/auth.service';
import { IncidentButtonComponent } from '../../components/incident-button/incident-button.component';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent implements OnInit {
  @ViewChild('table1') table1: ElementRef;

  public organisation: string;
  constructor(
    public tableService: TableserviceService,
    public authService: AuthService

  ) { }

  ngOnInit() {
    let lessor = this.authService.organisation;
    this.organisation = this.authService.organisation;
    this.tableService.getIncidentList(lessor);

  }
  settings = {
    actions: false,
    defaultStyle: false,
    pager: {
      perPage: 100
    },
    columns: {
      serialNumber: {
        title: 'SERIAL NUMBER',
      },
      registration: {
        title: 'REGISTRATION',
      },
      assetType: {
        title: 'ASSET TYPE',
      },
      assetModelSeries: {
        title: 'ASSET MODEL',
      },
      operator: {
        title: 'OPERATOR',
      },
      incidentDate: {
        title: 'INCIDENT DATE',
      },
      type: {
        title: 'TYPE',
        type: 'custom',
        renderComponent: TooltipComponent
      },
      title: {
        title: 'TITLE',
      },
      cause: {
        title: 'CAUSE'
      },
      flightNumber: {
        title: 'FLIGHT NUMBER',
      },
      info: {
        title: 'INFO',
        type: 'custom',
        renderComponent: IncidentButtonComponent
      }
    }
  };
  settingsAircastle = {
    actions: false,
    defaultStyle: false,
    pager: {
      perPage: 100
    },
    columns: {
      serialNumber: {
        title: 'SERIAL NUMBER',
      },
      registration: {
        title: 'REGISTRATION',
      },
      assetType: {
        title: 'ASSET TYPE',
      },
      assetModelSeries: {
        title: 'ASSET MODEL',
      },
      operator: {
        title: 'OPERATOR',
      },
      incidentDate: {
        title: 'INCIDENT DATE',
      },
      type: {
        title: 'TYPE',
        type: 'custom',
        renderComponent: TooltipComponent
      },
      title: {
        title: 'TITLE',
      },
      cause: {
        title: 'CAUSE'
      },
      flightNumber: {
        title: 'FLIGHT NUMBER',
      },
      techRep: {
        title: 'TECH REP',
      },
      marketingRep: {
        title: 'MARKETING REP',
      },
      info: {
        title: 'INFO',
        type: 'custom',
        renderComponent: IncidentButtonComponent
      }
    }
  };

}
