import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from './../../../configuration.json';
import * as Highcharts from 'highcharts';
import { TableserviceService } from './tableservice.service'
import HC_exporting from 'highcharts/modules/exporting';
import * as _ from 'lodash';
import { AUTO_STYLE } from '@angular/animations';
HC_exporting(Highcharts);

@Injectable({
  providedIn: 'root'
})
export class InsightServiceService {
  public tableData:any = [];
  public msnArray:any = [];
  public fcArray:any = [];
  public fhArray:any = [];
  public operators:any = [];
  public assetModelSeriesArr:any = [];
  public mRepArr:any = [];
  public techRepArr:any =[];
  public master: any = [];
  public assetType: any = [];
  public flightHours: any = [];
  public fhMin = 0;
  public fhMax = 0;
  public fhDetailArray: any = [];
  public totalFh = 0;
  public totalFc = 0;
  public fhSlider;
  public parent;
  public temp_operators = [];
  public temp_assetModelSeries = [];
  public temp_assetType = [];
  public temp_flightHours = [];
  public temp_mRep = [];
  public temp_techRep =[];
  public monthlyInsightsLoader: boolean = false; // Bar chart loader

  constructor(
    private httpClient: HttpClient,
    private tableService :TableserviceService
  ) { }

  initMonthlyInsight() {
    // console.log('Insight Service');
    let currentDate = new Date();
    // currentDate = JSON.stringify(currentDate);
    let currentDateStr = currentDate.toString();
    // console.log('Current date: ' +currentDateStr );
    let year = currentDateStr.split(' ')[3];
    // console.log(year);
    let month = currentDateStr.split(' ')[1];
    // console.log(month);
    //%Jun-2020
    let monthEnding = month+'-'+year;
    let clientData;
    // this.getClientDetails()
    let __this =this;
    let msnArr = [];
    let fhArr = [];
    let fcArr = [];
    __this.parent = null;
    return this.httpClient.get(config.apiUrl + '/uniform/monthlytotals/undefined/' + monthEnding)
    .toPromise()
    .then((response: any) => {
      // console.log('API CALL SUCCESS');
      if (response.response === 200) {
        let monthlyTotals = response.data
        // console.log(monthlyTotals);
        let monthlyTotalsTableDataArr = [];
        for (let i=0; i<Object.keys(monthlyTotals).length; i++) {
          let monthlyTotalsTableData:any = {};
          monthlyTotalsTableData.assetModelSeries = monthlyTotals[i].assetModelSeries;
          monthlyTotalsTableData.assetType  = monthlyTotals[i].assetType;
          monthlyTotalsTableData.operator = monthlyTotals[i].operator;
          monthlyTotalsTableData.flightHours = monthlyTotals[i].flightHours;
          monthlyTotalsTableData.flightCycles  = monthlyTotals[i].flightCycles;
          monthlyTotalsTableData.hex = monthlyTotals[i].hex;
          monthlyTotalsTableData.monthEnding = monthlyTotals[i].monthEnding;
          monthlyTotalsTableData.registration = monthlyTotals[i].registration;
          monthlyTotalsTableData.serialNumber = monthlyTotals[i].serialNumber;
          monthlyTotalsTableData.techRep = monthlyTotals[i].techRep;
          monthlyTotalsTableData.marketingRep = monthlyTotals[i].marketingRep;
          if (__this.tableService.onGroundArrayFinal.hasOwnProperty(monthlyTotalsTableData.serialNumber)) {
            monthlyTotalsTableData.daysOnGround = __this.tableService.onGroundArrayFinal[monthlyTotalsTableData.serialNumber].daysOnGround;
          } else {
            monthlyTotalsTableData.daysOnGround = '-';
          }
          monthlyTotalsTableDataArr.push(monthlyTotalsTableData);
        }
        // console.log('Initial Dataset');
        // console.log(monthlyTotalsTableDataArr);
        // let fhTotal = 0;
        // let insightFinalData = [];
        // for (let i=0;i<monthlyTotalsTableDataArr.length;i++) {
        //   let insightData:any = {};
        //   let serialNumber = monthlyTotalsTableDataArr[i].serialNumber;
        //   insightData[serialNumber] = monthlyTotalsTableDataArr[i].flightCycles;
        //   insightData[serialNumber] = monthlyTotalsTableDataArr[i].flightHours;
        //   insightFinalData.push(insightData);
        // }console.log(insightFinalData);
        // console.log("TEST monthlyTotalsTableDataArr");
        // console.log(monthlyTotalsTableDataArr);
        
        __this.master = monthlyTotalsTableDataArr;
        __this.tableData  = monthlyTotalsTableDataArr;
        //graph array creation
        let msnArr = [];
        let fhArr = [];
        let fcArr = [];
        let fhDetail = []
        for (let i=0; i<Object.keys(monthlyTotalsTableDataArr).length; i++) {
          msnArr.push(monthlyTotalsTableDataArr[i].serialNumber);
          let temp = {};
          temp["y"]= monthlyTotalsTableDataArr[i].flightHours;
          temp["msn"] = monthlyTotalsTableDataArr[i].serialNumber;
          temp["registration"] = monthlyTotalsTableDataArr[i].registration;
          temp["type"] =monthlyTotalsTableDataArr[i].assetModelSeries;
          temp["operator"] = monthlyTotalsTableDataArr[i].operator;
          fhDetail.push(temp);
          fhArr.push(monthlyTotalsTableDataArr[i].flightHours);
          fcArr.push(monthlyTotalsTableDataArr[i].flightCycles);
        }
        __this.fhDetailArray = fhDetail;
        __this.msnArray = msnArr;
        __this.fcArray = fcArr;
        __this.fhArray = fhArr;
        __this.totalFh = (__this.fhArray.reduce(function(acc, val) { return acc + val; }, 0)).toFixed(2);
        __this.totalFc = __this.fcArray.reduce(function(acc, val) { return acc + val; }, 0);
        // console.log("TOTAL FH = "+__this.totalFh);
        // console.log("TOTAL FC = "+__this.totalFc);
        // console.log('MSN ARRAY');
        // console.log(__this.msnArray);
        // console.log('FC ARRAY');
        // console.log(__this.fcArray);
        // console.log('FH ARRAY');
        // console.log(__this.fhArray);
        __this.graphUtilisation(__this.msnArray, __this.fhDetailArray, __this.fcArray);
        //initial dropdown boxes config
        let operators = [];
        let assetModelSeries = [];
        let assetType = [];
        let flightHours = [];
        let mRep = [];
        let techRep =[];
        for (let i =0; i<monthlyTotalsTableDataArr.length; i++) {
          if (!(operators.indexOf(monthlyTotalsTableDataArr[i].operator) >= 0)) {
            operators.push(__this.tableData[i].operator);
          }
          if (!(assetType.indexOf(monthlyTotalsTableDataArr[i].assetType) >= 0)) {
            assetType.push(__this.tableData[i].assetType);
          }
          if (!(flightHours.indexOf(monthlyTotalsTableDataArr[i].flightHours) >= 0)) {
            flightHours.push(__this.tableData[i].flightHours);
          }
          if (!(assetModelSeries.indexOf(monthlyTotalsTableDataArr[i].assetModelSeries) >= 0)) {
            assetModelSeries.push(monthlyTotalsTableDataArr[i].assetModelSeries);
          }
          if (!(mRep.indexOf(monthlyTotalsTableDataArr[i].marketingRep) >= 0)) {
            mRep.push(monthlyTotalsTableDataArr[i].marketingRep);
          }
          if (!(techRep.indexOf(monthlyTotalsTableDataArr[i].techRep) >= 0)) {
            techRep.push(monthlyTotalsTableDataArr[i].techRep);
          } else {
            continue;
          }
        }
        __this.operators = operators.sort((one, two) => (one > two ? 1 : -1));
        __this.assetType = assetType.sort((one, two) => (one > two ? 1 : -1));
        __this.flightHours = flightHours.sort((one, two) => (one > two ? 1 : -1));
        __this.assetModelSeriesArr = assetModelSeries.sort((one, two) => (one > two ? 1 : -1));
        __this.mRepArr = mRep.sort((one, two) => (one > two ? 1 : -1));
        __this.techRepArr =techRep.sort((one, two) => (one > two ? 1 : -1));
        __this.fhMin = Math.min(...__this.flightHours);
        __this.fhMax = Math.max(...__this.flightHours);
        // console.log('FLIGHT HOURSSSSSS');
        // console.log(__this.flightHours);
        // console.log('MIN FH VALUE: '+__this.fhMin);
        // console.log('MAX FH VALUE: '+__this.fhMax);          
      }
    })
  }
  arrayPrepGraph(monthlyTotalsTableDataArr: any) {
    console.log('Came to Array Prep for Graph');
    // for graph
    let __this =this;
    let msnArr = [];
    let fhArr = [];
    let fcArr = [];
    let fhDetail = []
    for (let i=0; i<Object.keys(monthlyTotalsTableDataArr).length; i++) {
      msnArr.push(monthlyTotalsTableDataArr[i].serialNumber);
      let temp = {};
      temp["y"]= monthlyTotalsTableDataArr[i].flightHours;
      temp["msn"] = monthlyTotalsTableDataArr[i].serialNumber;
      temp["registration"] = monthlyTotalsTableDataArr[i].registration;
      temp["type"] =monthlyTotalsTableDataArr[i].assetModelSeries;
      temp["operator"] = monthlyTotalsTableDataArr[i].operator;
      fhDetail.push(temp);
      fhArr.push(monthlyTotalsTableDataArr[i].flightHours);
      fcArr.push(monthlyTotalsTableDataArr[i].flightCycles);
    }
    __this.fhDetailArray = fhDetail;
    __this.msnArray = msnArr;
    __this.fcArray = fcArr;
    __this.fhArray = fhArr;
    // console.log('MSN ARRAY');
    // console.log(__this.msnArray);
    // console.log('FC ARRAY');
    // console.log(__this.fcArray);
    // console.log('FH ARRAY');
    // console.log(__this.fhArray);
    __this.totalFh = (__this.fhArray.reduce(function(acc, val) { return acc + val; }, 0)).toFixed(2);
    __this.totalFc = __this.fcArray.reduce(function(acc, val) { return acc + val; }, 0);
    console.log("TOTAL FH = "+__this.totalFh);
    console.log("TOTAL FC = "+__this.totalFc);
    
    
    __this.graphUtilisation(__this.msnArray, __this.fhDetailArray, __this.fcArray);    
  }

  arrayPrepDropDownBox(monthlyTotalsTableDataArr1: any, filterArray: any) {
    // for dropdown boxes
    // console.log('ARRAY PREP DROP DOWN BOX');
    // console.log('Data from filters');
    // console.log(monthlyTotalsTableDataArr1);
    // console.log('Filter Order Array');
    // console.log(filterArray);
    
    let __this =this;
    __this.parent = filterArray[0];
    console.log(monthlyTotalsTableDataArr1);
    console.log(filterArray);
    if (filterArray.indexOf('operator')<0 || !filterArray[filterArray.indexOf('operator')+1] == null) {      
      __this.temp_operators = [];
    }
    
    if (filterArray.indexOf('assetModelSeries')<0 || !filterArray[filterArray.indexOf('assetModelSeries')+1] == null) {
      __this.temp_assetModelSeries = [];
    }
    
    if (filterArray.indexOf('flightHours')<0 || !filterArray[filterArray.indexOf('flightHours')+1] == null) {
      __this.temp_flightHours = [];
    }
    
    if (filterArray.indexOf('mRep')<0 || !filterArray[filterArray.indexOf('mRep')+1] == null) {
      __this.temp_mRep = [];
    }
    
    if (filterArray.indexOf('techRep')<0 || !filterArray[filterArray.indexOf('techRep')+1] == null) {
      __this.temp_techRep = [];
    }
      
    if (filterArray.indexOf('assetType')<0 || !filterArray[filterArray.indexOf('assetType')+1] == null) {

      __this.temp_assetType = [];
    }
           
    

    for (let i=0; i<monthlyTotalsTableDataArr1.length;i++) {
      if (!(__this.temp_operators.indexOf(monthlyTotalsTableDataArr1[i].operator)>=0) && __this.parent!='operator') {
        __this.temp_operators.push(monthlyTotalsTableDataArr1[i].operator);
      }
      if (!(__this.temp_assetType.indexOf(monthlyTotalsTableDataArr1[i].assetType)>=0) && __this.parent!='assetType') {
        __this.temp_assetType.push(monthlyTotalsTableDataArr1[i].assetType);
      }
      if (!(__this.temp_flightHours.indexOf(monthlyTotalsTableDataArr1[i].flightHours)>=0) && __this.parent!='flightHours') {
        __this.temp_flightHours.push(monthlyTotalsTableDataArr1[i].flightHours);
      }    
      if (!(__this.temp_assetModelSeries.indexOf(monthlyTotalsTableDataArr1[i].assetModelSeries)>=0) && __this.parent!='assetModelSeries') {
        __this.temp_assetModelSeries.push(monthlyTotalsTableDataArr1[i].assetModelSeries);
      }      
      if (!(__this.temp_mRep.indexOf(monthlyTotalsTableDataArr1[i].marketingRep)>=0) && __this.parent!='mRep') {
        __this.temp_mRep.push(monthlyTotalsTableDataArr1[i].marketingRep);
      }
      if (!(__this.temp_techRep.indexOf(monthlyTotalsTableDataArr1[i].techRep)>=0) && __this.parent!='techRep') {
        __this.temp_techRep.push(monthlyTotalsTableDataArr1[i].techRep);
      }
    }
    for (let i =0; i<__this.master.length; i++) {
      switch(__this.parent) {
        case 'operator':  
          if (!(__this.temp_operators.indexOf(__this.master[i].operator) >= 0)) {
            __this.temp_operators.push(__this.master[i].operator);
          }
          break;
        case 'assetModelSeries': 
          if (!(__this.temp_assetModelSeries.indexOf(__this.master[i].assetModelSeries)>=0)) {
            __this.temp_assetModelSeries.push(__this.master[i].assetModelSeries);
          }
          break;
        case 'flightHours': 
          if (!(__this.temp_flightHours.indexOf(__this.master[i].flightHours) >= 0)) {
            __this.temp_flightHours.push(__this.master[i].flightHours);
          }
          break;
        case 'mRep': 
          if (!(__this.temp_mRep.indexOf(__this.master[i].marketingRep) >= 0)) {
            __this.temp_mRep.push(__this.master[i].marketingRep);
          }
          break;
        case 'techRep': 
          if (!(__this.temp_techRep.indexOf(__this.master[i].techRep) >= 0)) {
            __this.temp_techRep.push(__this.master[i].techRep);
          }
          break;
        case 'assetType': 
          if (!(__this.temp_assetType.indexOf(__this.master[i].assetType) >= 0)) {
            __this.temp_assetType.push(__this.master[i].assetType);
          }
          break;              
      }      
    }
    __this.operators = __this.temp_operators.sort((one, two) => (one > two ? 1 : -1));
    __this.assetType = __this.temp_assetType.sort((one, two) => (one > two ? 1 : -1));
    __this.flightHours = __this.temp_flightHours.sort((one, two) => (one > two ? 1 : -1));
    __this.assetModelSeriesArr = __this.temp_assetModelSeries.sort((one, two) => (one > two ? 1 : -1));
    __this.mRepArr = __this.temp_mRep.sort((one, two) => (one > two ? 1 : -1));
    __this.techRepArr =__this.temp_techRep.sort((one, two) => (one > two ? 1 : -1));
    __this.fhMin = Math.min(...__this.flightHours);
    __this.fhMax = Math.max(...__this.flightHours);
    // console.log('FLIGHT HOURSSSSSS');
    // console.log(__this.flightHours);
    // console.log('MIN FH VALUE: '+__this.fhMin);
    // console.log('MAX FH VALUE: '+__this.fhMax);
  }

  graphUtilisation(msnArr: any, fhArr: any, fcArr: any) {
    let __this = this;
    // console.log('GRAPH UTIL');
    Highcharts.chart('container', {
      chart: {
        type: 'column',
        events: {
          load: function () {
            __this.monthlyInsightsLoader = true
          }
        }, 
        scrollablePlotArea: {
          minWidth: 700,
          scrollPositionX: 1
        }
      },
      title: {
        text: 'Utilisation across month'
      },
      xAxis: {
        categories: msnArr
      },
      rangeSelector: {
        enabled: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'FH'
        },
        scrollbar: {
          enabled: true,
          showFull: false
        }
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          var point = this.points[0].point;
          var point1 = this.points[1].point;         
          return (point['color'] === '#6D63FF' ? '<b>Reg: ' + point['registration'] + '</b><br/> <b>MSN: ' + point['msn'] + '</b><br/> <b>Type: ' + point['type'] +'</b><br/><b>Operator: ' + point['operator'] + '</b><br/>':'') + '<span style="color:' + point['color'] + '">' + point['series'].name + '</span>: <b> ' + point.y + '</b><br/>' + '<span style="color:' + point1['color'] + '">' + point1['series'].name + '</span>: <b> ' + point1.y + '</b><br/>';
      },
        // pointFormatter: function () {
        //   var point =  this;
        //   var tooltip = (point.color === '#6D63FF' ? '<b>Reg: ' + point['registration'] + '</b><br/> <b>MSN: ' + point['msn'] + '</b><br/> <b>Type: ' + point['type'] +'</b><br/><b>Operator: ' + point['operator'] + '</b><br/>' : '') + '<span style="color:' + point.color + '">' + point.series.name + '</span>: <b> ' + point.y + '</b><br/>';
        //   return tooltip;
        // },
        shared: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'FH',
          data: fhArr,
          type: undefined,
          color: '#6D63FF',
        }, {
          name: 'FC',
          data: fcArr,
          type: undefined,
          color: '#00EFA5',
        }
      ]
    });
  }
}