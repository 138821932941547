<div class="text-dark">
  <!-- <form [formGroup]="dateForm" (ngSubmit)="onSubmit(dateForm)">
    <input type="month"  formControlName="monthEnd" datepicker-popup ng-model="dt">
    <button type="submit" class="btn btn-success ml-2">Submit</button>
  </form> -->
  <form autocomplete="off">
    <div class="card">
      <div class="card-body">
        <!-- <div class="row">
          <div class="col-sm-3 mb-2">
            <input type="text" name="dateRange" class="float-left form-control datepicker" id="datepicker" [(ngModel)]="dateRange">
          </div>
          <div class="col-sm-9 mb-2 text-left">
            <button class="float-left btn btn-success mt-1" (click)="onSubmit()">Submit</button>
          </div>
        </div> -->
        <div class="row align-items-center ">
          <div class="col- " id="from">
            <p class="from">From:</p>
          </div>
          <div class="col-md-auto mb-2">
            <input type="text" name="startdateRange" class="float-left form-control datepicker startDate" id="startDate"
                   [(ngModel)]="startDayNew">
          </div>
          <div class="col- " id="to">
            <p>To:</p>
          </div>
          <div class="col-md-auto mb-2">
            <input type="text" name="enddateRange" class="float-left form-control datepicker endDate" id="endDate"
                   [(ngModel)]="endDayNew">
          </div>
          <div class="col-md-auto mb-2 text-center" id="submit">
            <button class="float-left btn btn-success mt-1" (click)="onSubmit()">Submit</button>
          </div>
          <div class="col-md-auto mb-2 text-center" id="submit">
            <button class="float-left btn btn-success mt-1" (click)="clearFilters()">Clear All Filters</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput1" style="color: black;">Operator</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Type search here" [(ngModel)]="operator" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput2" style="color: black;">Departure</label>
            <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="Type search here" [(ngModel)]="departure" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput3" style="color: black;">Arrival</label>
            <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Type search here" [(ngModel)]="arrival" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <div class="card" *ngIf="dailyTableLoader === true">
        <app-table-loader></app-table-loader>
      </div>
      <div class="card" *ngIf="dailyTableLoader === false">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h4 class="text-uppercase card-title">Daily Transponder activity</h4>
            </div>
            <!-- Default switch -->
            <div class="custom-control col custom-switch">
              <input type="checkbox" (click)="getGroundBased()" class="custom-control-input" id="customSwitches">
              <label class="custom-control-label" for="customSwitches">Ground Based Activity</label>
            </div>

            <div class="col text-right" id='exportButton'>
              <div class="excelButton">
                <button (click)="this.tableService.exportToCsv('exportDaily')" class="btn btn-success">EXPORT</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="table-responsive text-dark" id="exportDaily" #table1>
                <ng2-smart-table *ngIf="isGroundBased"  [settings]="settings" [source]="this.dailyFlightsInAir">
                </ng2-smart-table>
                <ng2-smart-table *ngIf="!isGroundBased" [settings]="settings" [source]="this.allDailyFlights">
                </ng2-smart-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
