import { Component, OnInit } from '@angular/core';
import config from '../../../../configuration.json';
import { TableserviceService } from '../../services/tableservice.service';
import { HttpClient } from '@angular/common/http';
import { ExportToCsv } from 'export-to-csv';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthService } from '../../auth/auth.service';

// import jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
// import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
declare var $: any;


@Component({
  selector: 'app-daily-flown',
  templateUrl: './daily-flown.component.html',
  styleUrls: ['./daily-flown.component.css']
})
export class DailyFlownComponent implements OnInit {
  dailytotals: any = [];
  aircastleCheck: boolean;
  organisation: string;
  masterDaysFlownData:any =[];
  finalTableData: any = [];
  finalTableData1: any = [];
  testarr = [];
  dateRange: any;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dailyTableLoader: boolean = true;
  finalList = {};
  flown: any = {};
  serialNumberNew: any = [];
  headers = [];
  operator: any = [];
  assetType: any = [];
  assetModelSeries: any = [];
  mRep: any = [];
  techRep: any = [];
  operatorSelected: any = [];
  assetTypeSelected: any = [];
  assetModelSeriesSelected: any = [];
  mRepSelected: any = [];
  techRepSelected: any = [];
  filterOrder = [];
  masterAssetDetails: any = [];
  dropdownSettings:IDropdownSettings;
  parent: any;
  aircraftCount: any;
  engineCount: any;
  public temp_operators = [];
  public temp_assetModelSeries = [];
  public temp_assetType = [];
  public temp_mRep = [];
  public temp_techRep =[];
  public msnArray = [];


  constructor(private tableService: TableserviceService, public authService: AuthService, public httpClient: HttpClient) { }

  ngOnInit() {
    console.log("AUTH: "+this.authService.organisation);
    this.organisation = this.authService.organisation;
    let currentDate = new Date();
    this.dateRange = this.months[currentDate.getMonth()] + '-' + currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1);
    //console.log("This.Date Range : "+this.dateRange);
    let dateRange = currentDate.getFullYear() + '-' + (month < 10 ? '0' + month : month);
    //console.log("Date Range: "+dateRange);
    let daysInMonth = this.getDaysInMonth(month, currentDate.getFullYear());
    //console.log(daysInMonth);
    //console.log(dateRange);
    //console.log(daysInMonth)
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.aircraftCount=0;
    this.engineCount=0;
    this.operatorSelected = [];
    this.assetModelSeriesSelected =[];
    this.assetTypeSelected = [];
    this.mRepSelected =[];
    this.techRepSelected = [];
    this.filterOrder = [];
    this.populateHeaders(daysInMonth);
    // this.fetchData(dateRange);
    this.getClientDetails(daysInMonth, dateRange);

    //multi-select drop down list box intial values load
    this.ngAfterViewInit();


  }
  populateHeaders(daysInMonth: number) {
    if(this.organisation === 'aircastle'){
      this.aircastleCheck = true;
      this.headers = ["Serial Number","Registration", "Operator", "Asset Type", "Asset Model/Series", "Marketing Rep", "Tech Rep" ];
    }
    else {
      this.aircastleCheck = false;
      this.headers = ["Serial Number","Registration", "Operator", "Asset Type", "Asset Model/Series"];
    }
    for (let i = 1; i <= daysInMonth; i++) {
      this.headers.push(i);
    }
  }
  getDaysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
  }

  fetchData(dateRange: string) {
    let __this = this;
    //__this.dailytotals1 = [] // Reinitialize the array
    this.httpClient.get(config.apiUrl + '/uniform/dailyflownreport/' + dateRange)
      .toPromise()
      .then((response: any) => {
        //console.log(response);
        if (response.response === 200) {
          __this.dailytotals = response.data['flownAircraftList'];
          __this.serialNumberNew = response.data['allAssetList'];
          __this.masterAssetDetails = response.data['assetDetails'];
          __this.masterDaysFlownData = response.data['masterList'];
          // console.log("Flown Length:  "+Object.keys(__this.dailytotals).length);
          // console.log("Stationary Asset Length:  "+Object.keys(__this.serialNumberNew).length);
          // console.log( __this.masterAssetDetails);
          // console.log("Master all data in single object");
          // console.log(Object.keys(__this.masterDaysFlownData).length);
          
          this.operatorSelected = [];
          this.assetModelSeriesSelected =[];
          this.assetTypeSelected = [];
          this.mRepSelected =[];
          this.techRepSelected = [];
          this.filterOrder = [];
          __this.initialFilterPrep(__this.masterAssetDetails);

          //count for Aircraft and Engines
          // for(let i=0; i<__this.masterAssetDetails.length;i++){
          //   
          // }
          let aircraftCount = __this.masterAssetDetails.filter((obj) => obj.assetType === "Aircraft").length;
          let engineCount = __this.masterAssetDetails.filter((obj) => obj.assetType === "Engine").length;

          __this.aircraftCount = aircraftCount;
          __this.engineCount = engineCount;
          
          __this.finalTableData = __this.masterDaysFlownData;
          
          __this.dailyTableLoader = false; // Hide the loader
        }
      });
  }
  //Get Client Details
  getClientDetails(daysInMonth: any, dateRange: string) {
    //console.log('Getting client details');
    let __this = this;
    __this.fetchData(dateRange);
  }
  returnZero() {
    return 0;
  }

  // CSV
  exportToCsv() {
    let separator = ',';
    let rows = document.querySelectorAll('#contentToConvert tr')
    let csv = [];
    for (let i = 0; i < rows.length; i++) {
      let row = [], cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) {
        let data = (cols[j] as HTMLElement).innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, '')
        data = data.replace(/"/g, '""');
        row.push('"' + data + '"');
      }
      
      
      
      csv.push(row.join(separator));
    }
    //console.log("EXPORTINNG");
    
    
    let csv_string = csv.join('\n');
    //console.log(csv_string);
    // Download it
    let filename = 'daily-flown-report-' + new Date().toLocaleDateString() + '.csv'
    let link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ngAfterViewInit() {
    let __this = this;
    $('.datepicker').datepicker({
      format: "M-yyyy",
      startView: "months",
      minViewMode: "months",
      autoclose: true,
      endDate: '+0d',
    }).datepicker("setDate", 'now')
      .change(function () {
        __this.dateRange = $(this).val();
      });
  }

  onSubmit() {
    this.dailyTableLoader = true; // Show the loader
    let currentDate = new Date();
    let month = this.dateRange.split("-")[0];
    let year = this.dateRange.split("-")[1];
    let monthIndex = (this.months.indexOf(month) + 1);
    month = (monthIndex < 10 ? '0' + monthIndex : monthIndex).toString()
    let dateRange = year + "-" + month;
    let daysInMonth = this.getDaysInMonth(month, currentDate.getFullYear())
    // this.fetchData(dateRange);
    this.finalList = {};
    this.getClientDetails(daysInMonth, dateRange);
    this.populateHeaders(daysInMonth);
  }

  onSelected(val: any, target, action){
    // console.log("SELECTED : "+target);
    
    // console.log("On Selected: " +this.operatorSelected);
    // console.log("On Selected: " +this.assetModelSeriesSelected);
    // console.log("On Selected: " +this.assetTypeSelected);
    // console.log("On Selected: " +this.mRepSelected);
    // console.log("On Selected: " +this.techRepSelected);
    

    let __this = this;
    if (action == 'select') {
      if (!(__this.filterOrder.indexOf(target)>=0)) {
        __this.filterOrder.push(target);
      }
    }
    let operatorSelected = __this.operatorSelected;
    let assetTypeSelected = __this.assetTypeSelected;
    let assetModelSeriesSelected = __this.assetModelSeriesSelected;
    let mRepSelected = __this.mRepSelected;
    let techRepSelected = __this.techRepSelected;

    let firstFilter = __this.masterAssetDetails.filter((result: any) => {
      if(operatorSelected.length) {
        let isPresent = operatorSelected.includes(result.operator);
        if(isPresent) {
          return result;
        }
      } else {
        return __this.dailytotals;
      }
    }).filter((result1: any) => {
      if (assetTypeSelected.length) {
        let isPresent = assetTypeSelected.includes(result1.assetType);
        if (isPresent) {
          return result1;
        }
      } else {
        return result1;
      }
    }).filter((result2: any) => {
      if (assetModelSeriesSelected.length) {
        let isPresent = assetModelSeriesSelected.includes(result2.assetModelSeries);
        if (isPresent) {
          return result2;
        }
      }else {
        return result2;
      }
    }).filter((result3: any) => {
      if (mRepSelected.length) {
        let isPresent = mRepSelected.includes(result3.marketingRep);
        if (isPresent) {
          return result3;
        }
      } else {
        return result3;
      }
    }).filter((result4: any) => {
      if (techRepSelected.length) {
        let isPresent = techRepSelected.includes(result4.techRep);
        if (isPresent) {
          return result4;
        }
      } else {
        return result4;
      }
    });

    // console.log("FIRST FILTER: " );
    // console.log(firstFilter);
    
    
    // __this.tablePrep(firstFilter);
     __this.dropDownPrep(firstFilter, this.filterOrder);


  }

  onDeselect(val: any,target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch(target) {
      case 'operator':
        if (!this.operatorSelected.length) {
          //deselect all -- the filter values are null
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'operator') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'assetType':
        if (!this.assetTypeSelected.length) {
          //deselect all -- the filter values are null
            for(let i=0; i<this.filterOrder.length; i++) {
              if (this.filterOrder[i] === 'assetType') {
                this.filterOrder.splice(i,1);
              }
            }
            this.onSelected(val, target, action);
          } else {
            this.onSelected(val, target, action);
          }
          break;
      case 'assetModelSeries':
        if (!this.assetModelSeriesSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetModelSeries') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'mRep':
        if (!this.mRepSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'mRep') {
              this.filterOrder.splice(i,1);
            }
          }
          this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'techRep':
        if (!this.techRepSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'techRep') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
    }
  }
  onDeselectAll(val: any,target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.flightHoursSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch(target) {
      case 'operator':
        this.operatorSelected = [];
        for (let i=0;i<this.filterOrder.length;i++) {
          if (this.filterOrder[i] === 'operator') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'assetType':
        this.assetTypeSelected = [];
          for (let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetType') {
              this.filterOrder.splice(i,1);
            }
          }
          this.onSelected(val, target, action);
        break;
      case 'assetModelSeries':
        this.assetModelSeriesSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'assetModelSeries') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'mRep':
        this.mRepSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'mRep') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'techRep':
        this.techRepSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'techRep') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
    }
  }

  

  dropDownPrep(assetData: any, filterArray: any){
    console.log("ASSET DATA: ");
    console.log(assetData);
    
    console.log("Filter Array :"+filterArray);
    

    let __this =this;
    __this.tablePrep(assetData);
    __this.parent = filterArray[0];
    if (filterArray.indexOf('operator')<0 || !filterArray[filterArray.indexOf('operator')+1] == null) {      
      __this.temp_operators = [];
    }
    
    if (filterArray.indexOf('assetModelSeries')<0 || !filterArray[filterArray.indexOf('assetModelSeries')+1] == null) {
      __this.temp_assetModelSeries = [];
    }
    
    if (filterArray.indexOf('mRep')<0 || !filterArray[filterArray.indexOf('mRep')+1] == null) {
      __this.temp_mRep = [];
    }
    
    if (filterArray.indexOf('techRep')<0 || !filterArray[filterArray.indexOf('techRep')+1] == null) {
      __this.temp_techRep = [];
    }
      
    if (filterArray.indexOf('assetType')<0 || !filterArray[filterArray.indexOf('assetType')+1] == null) {

      __this.temp_assetType = [];
    }

    for (let i=0; i<assetData.length;i++) {
      if (!(__this.temp_operators.indexOf(assetData[i].operator)>=0) && __this.parent!='operator') {
        __this.temp_operators.push(assetData[i].operator);
      }
      if (!(__this.temp_assetType.indexOf(assetData[i].assetType)>=0) && __this.parent!='assetType') {
        __this.temp_assetType.push(assetData[i].assetType);
      }
      if (!(__this.temp_assetModelSeries.indexOf(assetData[i].assetModelSeries)>=0) && __this.parent!='assetModelSeries') {
        __this.temp_assetModelSeries.push(assetData[i].assetModelSeries);
      }      
      if (!(__this.temp_mRep.indexOf(assetData[i].marketingRep)>=0) && __this.parent!='mRep') {
        __this.temp_mRep.push(assetData[i].marketingRep);
      }
      if (!(__this.temp_techRep.indexOf(assetData[i].techRep)>=0) && __this.parent!='techRep') {
        __this.temp_techRep.push(assetData[i].techRep);
      }
    }



    for (let i =0; i<__this.masterAssetDetails.length; i++) {
      switch(__this.parent) {
        case 'operator':  
          if (!(__this.temp_operators.indexOf(__this.masterAssetDetails[i].operator) >= 0)) {
            __this.temp_operators.push(__this.masterAssetDetails[i].operator);
          }
          break;
        case 'assetModelSeries': 
          if (!(__this.temp_assetModelSeries.indexOf(__this.masterAssetDetails[i].assetModelSeries)>=0)) {
            __this.temp_assetModelSeries.push(__this.masterAssetDetails[i].assetModelSeries);
          }
          break;
        case 'mRep': 
          if (!(__this.temp_mRep.indexOf(__this.masterAssetDetails[i].marketingRep) >= 0)) {
            __this.temp_mRep.push(__this.masterAssetDetails[i].marketingRep);
          }
          break;
        case 'techRep': 
          if (!(__this.temp_techRep.indexOf(__this.masterAssetDetails[i].techRep) >= 0)) {
            __this.temp_techRep.push(__this.masterAssetDetails[i].techRep);
          }
          break;
        case 'assetType': 
          if (!(__this.temp_assetType.indexOf(__this.masterAssetDetails[i].assetType) >= 0)) {
            __this.temp_assetType.push(__this.masterAssetDetails[i].assetType);
          }
          break;              
      }      
    }

    __this.operator = __this.temp_operators.sort((one, two) => (one > two ? 1 : -1));
    __this.assetType = __this.temp_assetType.sort((one, two) => (one > two ? 1 : -1));
    __this.assetModelSeries = __this.temp_assetModelSeries.sort((one, two) => (one > two ? 1 : -1));
    __this.mRep = __this.temp_mRep.sort((one, two) => (one > two ? 1 : -1));
    __this.techRep =__this.temp_techRep.sort((one, two) => (one > two ? 1 : -1));

    console.log("FINAL DROPDOWN VALUES");
    
    console.log(__this.operator);
    console.log(__this.assetType);
    console.log(__this.assetModelSeries);
    console.log(__this.mRep);
    console.log(__this.techRep);
    
    
    
    
    
  }

  tablePrep(serialNumberArray: any){
    let __this = this;
    let msnArr = [];
    let aircraftCount = serialNumberArray.filter((obj) => obj.assetType === "Aircraft").length;
    let engineCount = serialNumberArray.filter((obj) => obj.assetType === "Engine").length;

    __this.aircraftCount = aircraftCount;
    __this.engineCount = engineCount;
    let masterTableData = __this.masterDaysFlownData;
   
    for(let i=0; i<serialNumberArray.length; i++){
      msnArr.push(serialNumberArray[i].serialClient);
    }
    console.log("TABLE PREP");
    console.log(msnArr);
    __this.msnArray = msnArr;
    let temp = {};

    for(let i=0; i<msnArr.length;i++){   
        temp[msnArr[i]] = masterTableData[msnArr[i]];
    }
    
    

    __this.finalTableData = temp;
    
    // console.log("FINALLY TEMP");
    // console.log(temp);
    
    
  }


  initialFilterPrep(masterAssetData) {
    let operator = [];
    let assetModelSeries = [];
    let assetType = [];
    let techRep = [];
    let mRep = [];
    // console.log("In FILTER PREP");
    
    let __this =this;
    for(let i =0; i<masterAssetData.length; i++){
      if (!(operator.indexOf(masterAssetData[i].operator) >= 0)){
        operator.push(masterAssetData[i].operator);
      }
      if (!(assetModelSeries.indexOf(masterAssetData[i].assetModelSeries) >= 0)){
        assetModelSeries.push(masterAssetData[i].assetModelSeries);
      }
      if (!(assetType.indexOf(masterAssetData[i].assetType) >= 0)){
        assetType.push(masterAssetData[i].assetType);
      }
      if (!(techRep.indexOf(masterAssetData[i].techRep) >= 0)){
        techRep.push(masterAssetData[i].techRep);
      }
      if (!(mRep.indexOf(masterAssetData[i].marketingRep) >= 0)){
        mRep.push(masterAssetData[i].marketingRep);
      }

      
    }

    __this.operator = operator;
    __this.assetModelSeries = assetModelSeries;
    __this.assetType = assetType;
    __this.techRep = techRep;
    __this.mRep = mRep;

  }


//   settings = {
//     actions: false,
//     defaultStyle: false,
//     pager: {
//       perPage: 500,
//     },
//     noDataMessage: 'No data to display',
//     columns: {
      
//     }
//   };
 
//   i=0;

//   public addColumn() {
//     this.settings.columns["new column " + this.i] = { title: 'new column ' + this.i.toString()};
//     this.settings = Object.assign({}, this.settings);
//     this.i++;
// }
  // captureScreen() {
  //   var data = document.getElementById('contentToConvert');  //Id of the table
  //   html2canvas(data).then(canvas => {
  //     // Few necessary setting options  
  //     let imgWidth = 205;
  //     let pageHeight = 295;
  //     let imgHeight = canvas.height * imgWidth / canvas.width;
  //     let heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf(); // A4 size page of PDF  
  //     let position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     pdf.save('Daily-Flown-Report.pdf'); // Generated PDF   
  //   });
  // }
  // makePDF() {

  //   var quotes = document.getElementById('contentToConvert');
  //   html2canvas(quotes)
  //     .then((canvas) => {
  //       //! MAKE YOUR PDF
  //       var pdf= new jspdf('p', 'pt', 'letter');

  //       for (var i = 0; i <= quotes.clientHeight / 980; i++) {
  //         //! This is all just html2canvas stuff
  //         var srcImg = canvas;
  //         var sX = 0;
  //         var sY = 980 * i; // start 980 pixels down for every new page
  //         var sWidth = 1170;
  //         var sHeight = 1514.118;//var setWidth = 1170; var setHeight = 1514.118;
  //         var dX = 0;
  //         var dY = 0;
  //         var dWidth = 900;
  //         var dHeight = 980;

  //         (window as any).onePageCanvas = document.createElement("canvas");
  //         (window as any).onePageCanvas.setAttribute('width', 900);
  //         (window as any).onePageCanvas.setAttribute('height', 980);
  //         var ctx = (window as any).onePageCanvas.getContext('2d');
  //         // details on this usage of this function: 
  //         // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
  //         ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

  //         // document.body.appendChild(canvas);
  //         var canvasDataURL = (window as any).onePageCanvas.toDataURL("image/png", 1.0);

  //         var width = (window as any).onePageCanvas.width;
  //         var height = (window as any).onePageCanvas.clientHeight;

  //         //! If we're on anything other than the first page,
  //         // add another page
  //         if (i > 0) {
  //           pdf.addPage(); //8.5" x 11" in pts (in*72)
  //         }

  //         //! now we declare that we're working on that page
  //         pdf.setPage(i + 1);
  //         //! now we add content to that page!
  //         pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width * .62), (height * .62));
  //         //canvasDataURL, 'PNG', 40, 40, (width*.62) - 190, (height*.62) - 90

  //       }
  //       //! after the for loop is finished running, we save the pdf.
  //       pdf.save('Test.pdf');
  //     })
  // }
}


