<div class="page">
  <div class="row">
    <div class="col-sm-2 mt-3">
      <div class="row mb-3">
        <h4 class="text-dark mt-2 text-uppercase">As of</h4>
        <h5 class="text-dark">{{this.monthEnd}}</h5>
      </div>
      <div class="row mb-3">
        <div class="card">
          <div class="card-body">
            <app-card-loader *ngIf="fleetActivityDailyLoaded === false"></app-card-loader>
            <div class="row" *ngIf="fleetActivityDailyLoaded === true">
              <div class="col-sm-12">
                <h6 class="text-dark text-center mt-2">Fleet Activity</h6>
                <h6 class="text-dark text-center">{{ day }}</h6>
              </div>
              <div class="col-sm-12 mt-4">
                <h3 class="text-center logo-primary">{{ fleetActivityDayAircraft }} Aircraft</h3>
              </div>
              <div class="col-sm-12 mt-2 mb-4">
                <h3 class="text-center logo-secondary">{{ fleetActivityDayEngine }} Engines</h3>
              </div>
            </div>
            <!-- <div id="container1"></div> -->
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="card">
          <div class="card-body">
            <app-card-loader *ngIf="fleetActivityMonthlyLoaded === false"></app-card-loader>
            <div class="row" *ngIf="fleetActivityMonthlyLoaded === true">
              <div class="col-sm-12">
                <h6 class="text-dark text-center mt-2">Fleet Activity</h6>
                <h6 class="text-dark text-center">{{ month }}</h6>
              </div>
              <div class="col-sm-12 mt-4">
                <h3 class="text-center logo-primary">{{ fleetActivityMonthAircraft }} Aircraft</h3>
              </div>
              <div class="col-sm-12 mt-2 mb-4">
                <h3 class="text-center logo-secondary">{{ fleetActivityMonthEngine }} Engines</h3>
              </div>
            </div>
            <!-- <div id="container2"></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-10 mt-3">
      <div id="chartdiv"></div>
    </div>

    <!-- <div class="col-sm-4 mb-3">
      <div class="card">
        <div class="card-body">
          <div id="container1"></div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mb-3">
      <div class="card">
        <div class="card-body">
          <div id="container2"></div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-sm-4 mb-3">
      <div class="card">
        <div class="card-body">
          <div id="container3"></div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-sm-12 mb-3">
      <div id="chartdiv"></div>
    </div> -->
    <div class="col-sm-12 my-3">
      <div class="card">
        <div class="card-header">
          <h4 class="text-uppercase card-title text-dark">Aircraft Location Data</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <input type="text" [(ngModel)]="term1" (keyup) = "captureChange($event)" class="form-control" id="search_test" placeholder="Type search here" style="margin:0px 0px 20px 0;float:left;" autocomplete="off">
            </div>
            <div class="col-sm-8 text-right">
              <div class="excelButton">
                <button (click)="exportToCsv()" class="btn btn-success">EXPORT </button>
              </div>
            </div>
            <div class="col-sm-12">
              <app-table-loader *ngIf="alrTableLoader === true"></app-table-loader>
              <div class="table-responsive" *ngIf="alrTableLoader === false" #table2>
                <table id="table2" class="table">
                  <thead>
                    <tr>
                      <!-- <th>Date</th> -->
                      <th class="text-uppercase">Serial Number</th>
                      <th class="text-uppercase">Registration</th>
                      <th class="text-uppercase">Asset Type</th>
                      <th class="text-uppercase">Type/Model</th>
                      <th class="text-uppercase">Operator</th>
                      <th class="text-uppercase">Days Since Landing</th>
                      <th class="text-uppercase">Date/Time</th>
                      <th class="text-uppercase">Location</th>
                    </tr>
                  </thead>
                  <tbody id="rowValues">
                    <tr *ngFor="let dailytotals of alrTableDataTwo | filter:term1 | paginate: {id: 'pagination2', itemsPerPage: 400, currentPage: p1 }; let i = index">
                      <td>{{dailytotals.serialNumber}}</td>
                      <td>{{dailytotals.registration}}</td>
                      <td>{{dailytotals.assetType}}</td>
                      <td>{{dailytotals.assetModelSeries}}</td>
                      <td>{{dailytotals.operator}}</td>
                      <td>{{dailytotals.daysOnGround}}</td>
                      <td>{{dailytotals.time}}</td>
                      <td>{{dailytotals.location}}</td>
                    </tr>
                    <tr *ngIf="alrTableDataTwo.length === 0" class="no-data-found-tr">
                      <td colspan="8" class="no-data-found-td">No data to display</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <pagination-controls id="pagination2" (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
