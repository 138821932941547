<div class="row">
    <svg                  
        class="col-sm-12 my-3"
        role="img"
        width="100%" 
        height="150"
        viewBox="0 0 280 100"
        aria-labelledby="loading-aria-table"
        preserveAspectRatio="none"
    >
        <title id="loading-aria-table">Loading...</title>
        <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            clip-path="url(#clip-path-table)"
            style='fill: url("#fill-table");'
            ></rect>
        <defs>
        <clipPath id="clip-path-table">
            <rect x="5" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="45" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="87" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="127" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="166" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="205" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="247" y="1" rx="0" ry="0" width="29" height="28" /> 
            <rect x="6" y="41" rx="0" ry="0" width="269" height="8" /> 
            <rect x="6" y="58" rx="0" ry="0" width="269" height="8" /> 
            <rect x="6" y="76" rx="0" ry="0" width="269" height="8" /> 
            <rect x="6" y="94" rx="0" ry="0" width="269" height="8" /> 
            <rect x="6" y="111" rx="0" ry="0" width="269" height="8" />
        </clipPath>
        <linearGradient id="fill-table">
            <stop
                offset="0.599964"
                stop-color="#f3f3f3"
                stop-opacity="1"
            >
                <animate
                    attributeName="offset"
                    values="-2; -2; 1"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                ></animate>
            </stop>
            <stop
                offset="1.59996"
                stop-color="#ecebeb"
                stop-opacity="1"
            >
                <animate
                    attributeName="offset"
                    values="-1; -1; 2"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                ></animate>
            </stop>
            <stop
                offset="2.59996"
                stop-color="#f3f3f3"
                stop-opacity="1"
            >
                <animate
                    attributeName="offset"
                    values="0; 0; 3"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                ></animate>
            </stop>
        </linearGradient>
        </defs>
    </svg>
</div>
