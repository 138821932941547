<div class="text-dark">
  <div class="card mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-3">
          <input type="text" name="dateRange" class="form-control datepicker" id="datepicker" [(ngModel)]="dateRange">
        </div>
        <div class="col-sm-1">
          <button class="btn btn-success" (click)="onSubmit()">Submit</button>
        </div>
        <div class="col-sm-4">
          <button class="btn btn-success" (click)="ngOnInit()">Clear All Filters</button>
        </div>
        <div class="col-sm-4 text-right">
          <a class = "labeltextfh">Total Flight Hours: {{this.insightService.totalFh}}</a> <br>
          <a class = "labeltextfc">Total Flight Cycles: {{this.insightService.totalFc}}</a>
        </div>
      </div>
      <!-- Filters start -->
      <div class="row mt-3">
        <div class="col-sm-3">
          <ng-multiselect-dropdown
            [placeholder]="'Select Operator'"
            [data]="this.insightService.operators"
            [(ngModel)]="operatorSelected"
            [settings]="dropdownSettings"
            (onSelect)="onSelected($event, 'operator','select')"
            (onSelectAll)="onSelected($event, 'operator','select')"
            (onDeSelect) ="onDeselect($event, 'operator','deselect')"
            (onDeSelectAll) ="onDeselectAll($event, 'operator','deselectAll')"
            ></ng-multiselect-dropdown>
        </div>
        <div class="col-sm-3">
          <ng-multiselect-dropdown
          [placeholder]="'Select Asset Type'"
          [data]="this.insightService.assetType"
          [(ngModel)]="assetTypeSelected"
          [settings]="dropdownSettings"
          (onSelect)="onSelected($event, 'assetType','select')"
          (onSelectAll)="onSelected($event, 'assetType','select')"
          (onDeSelect) ="onDeselect($event, 'assetType','deselect')"
          (onDeSelectAll) ="onDeselectAll($event, 'assetType','deselectAll')"
          ></ng-multiselect-dropdown>
        </div>
        <div class="col-sm-3">
          <ng-multiselect-dropdown
            [placeholder]="'Select Asset Model'"
            [data]="this.insightService.assetModelSeriesArr"
            [(ngModel)]="assetModelSeriesSelected"
            [settings]="dropdownSettings"
            (onSelect)="onSelected($event, 'assetModelSeries','select')"
            (onSelectAll)="onSelected($event, 'assetModelSeries','select')"
            (onDeSelect) ="onDeselect($event, 'assetModelSeries','deselect')"
            (onDeSelectAll) ="onDeselectAll($event, 'assetModelSeries','deselectAll')"
            ></ng-multiselect-dropdown>
        </div>
        <div class="col-sm-3">
          <ng-multiselect-dropdown *ngIf="this.authService.organisation === 'aircastle'"
            [placeholder]="'Select Marketing Rep'"
            [data]="this.insightService.mRepArr"
            [(ngModel)]="mRepSelected"
            [settings]="dropdownSettings"
            (onSelect)="onSelected($event, 'mRep','select')"
            (onSelectAll)="onSelected($event, 'mRep','select')"
            (onDeSelect) ="onDeselect($event, 'mRep','deselect')"
            (onDeSelectAll) ="onDeselectAll($event, 'mRep','deselectAll')"
            ></ng-multiselect-dropdown>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-3">
          <ng-multiselect-dropdown *ngIf="this.authService.organisation === 'aircastle'"
            [placeholder]="'Select Tech Rep'"
            [data]="this.insightService.techRepArr"
            [(ngModel)]="techRepSelected"
            [settings]="dropdownSettings"
            (onSelect)="onSelected($event, 'techRep','select')"
            (onSelectAll)="onSelected($event, 'techRep','select')"
            (onDeSelect) ="onDeselect($event, 'techRep','deselect')"
            (onDeSelectAll) ="onDeselectAll($event, 'techRep','deselectAll')"
            ></ng-multiselect-dropdown>
        </div>
        <!-- <div class="col-sm-5 offset">
          <label for="customRange2" class="form-label">Flight Hours</label>
          <label for="customRange2" class="sliderText"> {{insightService.fhMin}}</label>
          <input type="range" class="form-range" [min]="this.insightService.fhMin" [max]="this.insightService.fhMax"  [value]="flightHoursSelected1" (input)="flightHoursSelected1 = $event.target.value"  (change) ="onSelected($event, 'flightHours','select')">
          <label for="customRange2" class="sliderText"> {{insightService.fhMax}}</label>
          <button class="btn" (click)="onDeselect($event, 'flightHours','deselect')"><i class="fa fa-close"></i></button>
        </div> -->
      </div>
      <!-- Filters ends -->
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <div class="card">
        <div class="card-body">
          <app-barchart-loader style="height: 400px;" *ngIf="this.insightService.monthlyInsightsLoader === false"></app-barchart-loader>
          <div id="container"></div>
        </div>
      </div>
    </div>
  </div>
</div>
