<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <app-table-loader *ngIf="this.tableService.incidentTableLoader === true"></app-table-loader>
          <div class="table-responsive text-dark" id="incident" *ngIf="this.tableService.incidentTableLoader === false" #table1>
            <ng2-smart-table *ngIf="this.organisation != 'aircastle'" [settings]="settings" [source]="this.tableService.incidentTableData"></ng2-smart-table>
            <ng2-smart-table *ngIf="this.organisation === 'aircastle'" [settings]="settingsAircastle" [source]="this.tableService.incidentTableData"></ng2-smart-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>