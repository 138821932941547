<div class="page">
  <!-- <input type="month" formControlName="monthEnd" datepicker-popup ng-model="dt"> -->
  <form autocomplete="off">
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center ">
          <div class="col- " id="from">
            <p class="from">From:</p>
          </div>
          <div class="col-md-auto mb-2">
            <input type="text" name="startdateRange" class="float-left form-control datepicker startDate" id="startDate"
              [(ngModel)]="startNewMonth">
          </div>
          <div class="col- " id="to">
            <p>To:</p>
          </div>
          <div class="col-md-auto mb-2">
            <input type="text" name="enddateRange" class="float-left form-control datepicker endDate" id="endDate"
              [(ngModel)]="endNewMonth">
          </div>
          <div class="col-md-auto mb-2 text-center" id="submit">
            <button class="float-left btn btn-success mt-1" (click)="onSubmit()">Submit</button>
          </div>
          <div class="col-md-auto mb-2 text-center" id="submit">
            <button class="float-left btn btn-success mt-1" (click)="clearFilters()">Clear All Filters</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput1" style="color: black;">Asset Type</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Type search here" [(ngModel)]="assetType" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput1" style="color: black;">Aircraft Type</label>
            <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="Type search here" [(ngModel)]="aircraftType" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3">
            <label for="exampleFormControlInput1" style="color: black;">Operator</label>
            <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Type search here" [(ngModel)]="operator" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3" *ngIf="this.organisation === 'aircastle'">
            <label for="exampleFormControlInput1" style="color: black;">Tech Rep</label>
            <input type="text" class="form-control" id="exampleFormControlInput4" placeholder="Type search here" [(ngModel)]="techRep" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group col-sm-3 mt-1" *ngIf="this.organisation === 'aircastle'">
            <label for="exampleFormControlInput1" style="color: black;">Marketing Rep</label>
            <input type="text" class="form-control" id="exampleFormControlInput5" placeholder="Type search here" [(ngModel)]="marketingRep" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-table></app-table>
</div>
