<div class="row">
  <div class="col-sm-12 mt-3">
    <div class="card" *ngIf="this.tableService.monthlyTotalsLoader === true">
      <app-table-loader></app-table-loader>
    </div>
    <div class="card" *ngIf="this.tableService.monthlyTotalsLoader === false">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6">
            <h4 class="text-dark card-title text-uppercase">Monthly totals</h4>
          </div>
          <div class="col-sm-6 text-right" id="exportButton">
            <div class="excelButton">
              <button (click)="this.tableService.exportToCsv('exportMonthly')" class="btn btn-success">EXPORT</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive text-dark" id="exportMonthly" #table1>
          <ng2-smart-table *ngIf="this.organisation === 'aircastle'" [settings]="settingsAircastle"
            [source]="this.tableService.tableData"></ng2-smart-table>
          <ng2-smart-table *ngIf="this.organisation != 'aircastle'" [settings]="settings"
            [source]="this.tableService.tableData"></ng2-smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
