<div class="container-fluid">

</div>
<!-- <div class="col-sm-8 text-right">
      <div class="excelButton">
        <button (click)="captureScreen()" class="btn btn-success">EXPORT to PDF</button>
      </div>
      </div>
      <div class="col-sm-8 text-right">
        <div class="excelButton">
          <button (click)="exportToCsv()" class="btn btn-success">EXPORT to CSV </button>
        </div>
      </div> -->
<div class="row">
  <div class="col-sm-12">
    <div class="card m-3 p-0">
      <div class="card-body p-3">
        <div class="row mt-2">
          <div class="col-sm-12 mt-2">
            <form autocomplete="off">
              <div class="row">
                <div class="col-sm-3 mb-2">
                  <input type="text" name="dateRange" class="float-left form-control datepicker"
                    id="datepicker-days-flown" [(ngModel)]="dateRange">
                </div>
                <div class="col-sm-4 float-left">
                  <button class="float-left btn btn-success mt-1" (click)="onSubmit()">Submit</button>
                  <button class="float-left btn btn-success mt-1 ml-2" (click)="ngOnInit()">Clear All Filters</button>
                  <button class="float-left btn btn-success mt-1 ml-2" (click)="exportToCsv()">EXPORT</button>
                </div>

                <!-- <button (click)="captureScreen()" class="btn btn-success">EXPORT to PDF</button> -->
                <!-- <div ngbDropdown class="d-inline-block">
                          <button class="btn btn-success float-right" id="dropdownBasic" ngbDropdownToggle>EXPORT</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic" style="min-width: 250px;">
                            <div class="dropdown-divider"></div>
                          </div>
                        </div> -->

                <div class="col-sm-5 text-right">
                  <a class="labeltextaircraft">Aircraft: {{this.aircraftCount}}</a><br />
                  <a class="labeltextengine">Engine: {{this.engineCount}}</a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3">


          <div class="col-sm-3">
            <ng-multiselect-dropdown [placeholder]="'Select Operator'" [data]="operator" [(ngModel)]="operatorSelected"
              [settings]="dropdownSettings" (onSelect)="onSelected($event, 'operator','select')"
              (onSelectAll)="onSelected($event, 'operator','select')"
              (onDeSelect)="onDeselect($event, 'operator','deselect')"
              (onDeSelectAll)="onDeselectAll($event, 'operator','deselectAll')"></ng-multiselect-dropdown>
          </div>
          <div class="col-sm-3">
            <ng-multiselect-dropdown [placeholder]="'Select Asset Type'" [data]="assetType"
              [(ngModel)]="assetTypeSelected" [settings]="dropdownSettings"
              (onSelect)="onSelected($event, 'assetType','select')"
              (onSelectAll)="onSelected($event, 'assetType','select')"
              (onDeSelect)="onDeselect($event, 'assetType','deselect')"
              (onDeSelectAll)="onDeselectAll($event, 'assetType','deselectAll')"></ng-multiselect-dropdown>
          </div>
          <div class="col-sm-3">
            <ng-multiselect-dropdown [placeholder]="'Select Model/Series'" [data]="assetModelSeries"
              [(ngModel)]="assetModelSeriesSelected" [settings]="dropdownSettings"
              (onSelect)="onSelected($event, 'assetModelSeries','select')"
              (onSelectAll)="onSelected($event, 'assetModelSeries','select')"
              (onDeSelect)="onDeselect($event, 'assetModelSeries','deselect')"
              (onDeSelectAll)="onDeselectAll($event, 'assetModelSeries','deselectAll')"></ng-multiselect-dropdown>
          </div>
          <div class="col-sm-3">
            <ng-multiselect-dropdown *ngIf="aircastleCheck === true" [placeholder]="'Select Marketing Rep'"
              [data]="mRep" [(ngModel)]="mRepSelected" [settings]="dropdownSettings"
              (onSelect)="onSelected($event, 'mRep','select')" (onSelectAll)="onSelected($event, 'mRep','select')"
              (onDeSelect)="onDeselect($event, 'mRep','deselect')"
              (onDeSelectAll)="onDeselectAll($event, 'mRep','deselectAll')"></ng-multiselect-dropdown>
          </div>
          <div class="col-sm-3 mt-3">
            <ng-multiselect-dropdown *ngIf="aircastleCheck === true" [placeholder]="'Select Tech Rep'" [data]="techRep"
              [(ngModel)]="techRepSelected" [settings]="dropdownSettings"
              (onSelect)="onSelected($event, 'techRep','select')" (onSelectAll)="onSelected($event, 'techRep','select')"
              (onDeSelect)="onDeselect($event, 'techRep','deselect')"
              (onDeSelectAll)="onDeselectAll($event, 'techRep','deselectAll')"></ng-multiselect-dropdown>
          </div>

          <!-- <div class="col-sm-5 offset">
                  <label for="customRange2" class="form-label">Flight Hours</label>
                  <label for="customRange2" class="sliderText"> {{insightService.fhMin}}</label>
                  <input type="range" class="form-range" [min]="this.insightService.fhMin" [max]="this.insightService.fhMax"  [value]="flightHoursSelected1" (input)="flightHoursSelected1 = $event.target.value"  (change) ="onSelected($event, 'flightHours','select')">
                  <label for="customRange2" class="sliderText"> {{insightService.fhMax}}</label>
                  <button class="btn" (click)="onDeselect($event, 'flightHours','deselect')"><i class="fa fa-close"></i></button>
                </div> -->

        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <input *ngIf="aircastleCheck === true" class="toggle-column-1" type="checkbox" checked> <label
          *ngIf="aircastleCheck === true">Display Asset Details</label>
        <input *ngIf="aircastleCheck === false" class="toggle-column-2" type="checkbox" checked> <label
          *ngIf="aircastleCheck === false">Display Asset Details</label>
        <app-table-loader *ngIf="dailyTableLoader === true"></app-table-loader>
        <div class="table-responsive">
          <table class="table table-bordered" id="contentToConvert" *ngIf="dailyTableLoader === false">
            <tr>
              <th *ngFor="let column of headers">{{ column }}</th>
            </tr>
            <tr *ngFor="let x of finalTableData | keyvalue: returnZero">
              <td class="parentCell" *ngFor="let i of x.value | keyvalue: returnZero">
                {{  i.value['value'] }}
              </td>
            </tr>
          </table>
        </div>

        <!-- <div class="table-responsive text-dark" id="exportMonthly" #table1>
                  <ng2-smart-table *ngIf="this.organisation === 'aircastle'" [settings]="settings" [source]="this.finalTableData"></ng2-smart-table>
                  <ng2-smart-table *ngIf="this.organisation != 'aircastle'" [settings]="settings" [source]="this.finalTableData"></ng2-smart-table>
                </div> -->

      </div>
      <div>
        <div>
        </div>
      </div>
    </div>
  </div>
