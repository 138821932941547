<!-- <script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="https://code.highcharts.com/modules/accessibility.js"></script> -->
<div class="page text-dark">
  <div class="row">
    <div class="col-sm-3">
      <div class="card">
        <div class="card-body">
          <div class="col-sm-12" style="min-height: 400px;">
            <h5 class="text-uppercase" style="font-size: 0.9rem;">Monthly flight breakdown</h5>
            <h6 class="mt-3">MSN</h6>
            <div>{{this.tableserviceService.msn}}</div>
            <h6 class="mt-3">Reg</h6>
            <div>{{this.tableserviceService.reg}}</div>
            <h6 class="mt-3">ACTYPE</h6>
            <div>{{this.tableserviceService.acType}}</div>
            <h6 class="mt-3">Operator</h6>
            <div>{{this.tableserviceService.operator}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="card">
        <div class="card-body">
          <app-barchart-loader style="height: 400px;" *ngIf="this.tableserviceService.utilisationAcrossMonthBarChartLoader === false"></app-barchart-loader>
          <div id="container"></div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-3">
      <div class="card" *ngIf="this.tableserviceService.tableData?.length === 0">
        <app-table-loader></app-table-loader>
      </div>
      <div class="card" *ngIf="this.tableserviceService.tableData?.length > 0">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <input type="text" [(ngModel)]="term1" class="form-control" placeholder="Search here"  id="search_monthly_info" style="margin:0px 0px 20px 0;float:left;" autocomplete="off">
            </div>
            <div class="col-sm-8 text-right">
              <div ngbDropdown class="d-inline-block">
                <button class="btn" id="dropdownBasic" ngbDropdownToggle>EXPORT</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic" style="min-width: 250px;">
                  <button (click)="this.tableserviceService.exportToCsv('monthlyFlightBreakdown')" id = "exportToExcel">Export To CSV</button>
                  <div class="dropdown-divider"></div>
                  <button (click)="this.tableserviceService.getMonthlyFlightList()" type="button" id = "exportDailyFlights" >Export List of Flights
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="table-responsive" id="monthlyFlightBreakdown" #table2>
                <table id="table2" class="table">
                  <thead>
                    <tr>
                      <th class="text-uppercase">Registration</th>
                      <th class="text-uppercase">Serial Number</th>
                      <th class="text-uppercase">Operator</th>
                      <th class="text-uppercase">Date</th>
                      <th class="text-uppercase text-right">FH</th>
                      <th class="text-uppercase text-right">FC</th>
                      <!--<th>Airport Incursion</th>-->
                      <th class="text-uppercase text-center">Info</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(this.tableserviceService.tableData[0] | filter: term1 | paginate: {id: 'pagination2', itemsPerPage: 200, currentPage: p1 }) as result">
                    <tr *ngFor="let dailytotals of result; let i = index">
                      <td>{{dailytotals.registration}}</td>
                      <td>{{dailytotals.serialNumber}}</td>
                      <td>{{dailytotals.operator}}</td>
                      <td>{{dailytotals.date}}</td>
                      <td class="text-right">{{dailytotals.flightHours | number : '1.2-2'}}</td>
                      <td class="text-right">{{dailytotals.flightCycles}}</td>
                      <!--<td>{{dailytotals.airportIncursion}}</td>-->
                      <td class="text-center"><button id="button"  [routerLink] = "['/dailyinfo']" (click)="this.tableserviceService.dailyBreakdown(dailytotals)" type="button" class="btn btn-info" > info</button></td>
                    </tr>
                    <tr *ngIf="result.length === 0" class="no-data-found-tr">
                      <td colspan="8" class="no-data-found-td">No data to display</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <pagination-controls id="pagination2" (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
