import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { MonthComponent } from 'src/app/pages/month/month.component';
import {TableserviceService} from '../../services/tableservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-button-view',
  templateUrl: './button-view-component.component.html',
  styleUrls: ['./button-view-component.component.css']
})
export class ButtonViewComponent implements ViewCell, OnInit {
  renderValue: string;

  constructor(
    private tableService :TableserviceService,
    private router :Router

  ) { }

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();



  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
    this.router.navigate(['/monthlyinfo']);
    this.tableService.monthlyBreakdown(this.rowData, 'true');

    //[routerLink] = "['/monthlyinfo']" (click)="this.tableService.monthlyBreakdown(tableData,'true')"


  }
}
