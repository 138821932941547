import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TableserviceService } from '../../services/tableservice.service';
import { Http, Headers, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as xlsx from 'xlsx';
import { HttpClient } from '@angular/common/http';
import config from '../../../../configuration.json'
import { AuthService } from '../../auth/auth.service';
import { ExportToCsv } from 'export-to-csv';
import { ButtonViewComponent } from '../button-view-component/button-view-component.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @ViewChild('table1') table1: ElementRef;

  serialNumber: any = [];
  hex: any = [];
  onGroundArrayFinal: any = {};
  public tableData: any = [];
  organisation
  

  constructor(
    public tableService: TableserviceService,
    public http: Http,
    public httpClient: HttpClient,
    public auth: AuthService,


  ) { }
  ngOnInit() {
    this.organisation = this.auth.organisation;
    // console.log(this.auth);
    // console.log(this.auth.loggedIn);
    // console.log(this.auth.organisation);
    // console.log(this.organisation);

    //console.log('Inside table component');


    var __this = this;
    this.getClientDetails().then(function (res) {
      return __this.serialNumber;
    }).then(function (serialNumber) {
      //console.log("FLAG 1");
      return __this.tableService.getAOG(serialNumber)
        .then(function () {
          __this.tableService.initMonthlyTable();
          __this.tableService.monthlyTotalsLoader = false; // Hide loader
          //console.log(__this.tableService.onGroundArrayFinal);
        })
    })
  }
  settings = {
    actions: false,
    defaultStyle: false,
    pager: {
      display:false
    },
    noDataMessage: 'No data to display',
    columns: {
      serialNumber: {
        title: 'SERIAL NUMBER'
      },
      registration: {
        title: 'REGISTRATION'
      },
      assetType: {
        title: 'ASSET TYPE'
      },
      assetModelSeries: {
        title: 'AIRCRAFT TYPE'
      },
      operator: {
        title: 'OPERATOR'
      },
      monthEnding: {
        title: 'MONTH ENDING'
      },
      flightHours: {
        title: 'FLIGHT HOURS'
      },
      flightCycles: {
        title: 'FLIGHT CYCLES'
      },
      daysOnGround: {
        title: 'DAYS SINCE LANDING'
      },
      hex: {
        title: 'ICAO HEX'
      },
      info: {
        title: 'INFO',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            // console.log(instance);
            // console.log(row);
            instance.tableService.monthlyBreakdown(instance.rowData, 'true');
          });
        }
      }
    }
  };
  settingsAircastle = {
    actions: false,
    defaultStyle: false,
    pager: {
      display:false
    },
    columns: {
      serialNumber: {
        title: 'SERIAL NUMBER',

      },
      registration: {
        title: 'REGISTRATION',

      },
      assetType: {
        title: 'ASSET TYPE',
      },
      assetModelSeries: {
        title: 'AIRCRAFT TYPE',
      },
      operator: {
        title: 'OPERATOR',
      },
      monthEnding: {
        title: 'Month Ending',
      },
      flightHours: {
        title: 'FLIGHT HOURS',
      },
      flightCycles: {
        title: 'FLIGHT CYCLES',
      },
      daysOnGround: {
        title: 'DAYS SINCE LANDING'
      },
      hex: {
        title: 'ICAO HEX',
      },
      techRep: {
        title: 'TECH REP',
      },
      marketingRep: {
        title: 'MARKETING REP',
      },
      info: {
        title: 'INFO',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            // console.log(instance);
            // console.log(row);
            instance.tableService.monthlyBreakdown(instance.rowData, 'true');
          });
        }
      }
    }
  };
 
    
 
  // exportToCsv() {
  //   if (this.organisation === 'aircastle') {
  //     let monthlyExportData = this.tableService.tableData;
  //     let resultMonthlyData = monthlyExportData.map(({ serialNumber, registration, assetType, assetModelSeries, operator, monthEnding, flightHours, flightCycles, daysOnGround, hex, techRep, marketingRep }) => ({ serialNumber, registration, assetType, assetModelSeries, operator, monthEnding, flightHours, flightCycles, daysOnGround, hex, techRep, marketingRep }));
  //     console.log(resultMonthlyData);
      
  //     var filename = "Export Monthly"
  //     const options = {
  //       fieldSeparator: ',',
  //       quoteStrings: '"',
  //       decimalSeparator: '.',
  //       showLabels: true,
  //       useTextFile: false,
  //       useBom: true,
  //       useKeysAsHeaders: true,
  //       filename: filename,
  //     };
  //     const csvExporter = new ExportToCsv(options);
  //     csvExporter.generateCsv(resultMonthlyData);
  //   } else {
  //     let monthlyExportData = this.tableService.tableData;
  //     let resultMonthlyData = monthlyExportData.map(({ serialNumber, registration, assetType, assetModelSeries, operator, monthEnding, flightHours, flightCycles, daysOnGround, hex }) => ({ serialNumber, registration, assetType, assetModelSeries, operator, monthEnding, flightHours, flightCycles, daysOnGround, hex }));
  //     var filename = "Export Monthly"
  //     const options = {
  //       fieldSeparator: ',',
  //       quoteStrings: '"',
  //       decimalSeparator: '.',
  //       showLabels: true,
  //       useTextFile: false,
  //       useBom: true,
  //       useKeysAsHeaders: true,
  //       filename: filename,
  //     };
  //     const csvExporter = new ExportToCsv(options);
  //     csvExporter.generateCsv(resultMonthlyData);
  //   }

    //console.log('Export to CSV Data',resultMonthlyData);



    //console.log('Inside Export to CSV');


  //}

  getClientDetails() {
    // console.log('Getting ClientDetails...');
    var __this = this;
    return this.httpClient.get(config.apiUrl + '/uniform/clientdetails/undefined')
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let clientDetails = response.data
          var serialNumber = [];
          for (var i = 0; i < clientDetails.length; i++) {
            //hex.push(clientDetails[i].hex)
            serialNumber.push(clientDetails[i].serialNumber)
          }
          __this.serialNumber = serialNumber;
        }
      })
  }
}
