<div class="row">
  <div class="col-sm-12">
    <div class="card m-3 p-0">
      <div class="card-body p-3">
        <div class="row mt-2">
          <div class="col-sm-12 mt-2">
            <form autocomplete="off">
              <div class="row">
                <div class="col-sm-3 mb-2">
                  <input type="number" [(ngModel)]="daysOnGroundSelected" [ngModelOptions]="{standalone: true}" (keyup)="onSelected($event, 'daysOnGround','select')" class="form-control" id="search_test"
                    placeholder="Type minimum days on ground" style="margin:0px 0px 20px 0;float:left;" autocomplete="off">
                </div>
                <div class="col-sm-4 float-left">
                  <button class="float-left btn btn-success mt-1 ml-2" (click)="ngOnInit()">Clear All Filters</button>
                  <button class="float-left btn btn-success mt-1 ml-2" (click)="exportToCsv()">EXPORT</button>
                </div>
                <div class="col-sm-5 text-right">
                  <a class="labeltextaircraft">Aircraft: {{this.aircraftCount}}</a><br />
                  <a class="labeltextengine">Engine: {{this.engineCount}}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <ng-multiselect-dropdown [placeholder]="'Select Operator'" [data]="operator" [(ngModel)]="operatorSelected" [ngModelOptions]="{standalone: true}"
                    [settings]="dropdownSettings" (onSelect)="onSelected($event, 'operator','select')"
                    (onSelectAll)="onSelected($event, 'operator','select')"
                    (onDeSelect)="onDeselect($event, 'operator','deselect')"
                    (onDeSelectAll)="onDeselectAll($event, 'operator','deselectAll')"></ng-multiselect-dropdown>
                </div>
                <div class="col-sm-3">
                  <ng-multiselect-dropdown [placeholder]="'Select Asset Type'" [data]="assetType" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="assetTypeSelected" [settings]="dropdownSettings"
                    (onSelect)="onSelected($event, 'assetType','select')"
                    (onSelectAll)="onSelected($event, 'assetType','select')"
                    (onDeSelect)="onDeselect($event, 'assetType','deselect')"
                    (onDeSelectAll)="onDeselectAll($event, 'assetType','deselectAll')"></ng-multiselect-dropdown>
                </div>
                <div class="col-sm-3">
                  <ng-multiselect-dropdown [placeholder]="'Select Model/Series'" [data]="assetModelSeries" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="assetModelSeriesSelected" [settings]="dropdownSettings"
                    (onSelect)="onSelected($event, 'assetModelSeries','select')"
                    (onSelectAll)="onSelected($event, 'assetModelSeries','select')"
                    (onDeSelect)="onDeselect($event, 'assetModelSeries','deselect')"
                    (onDeSelectAll)="onDeselectAll($event, 'assetModelSeries','deselectAll')"></ng-multiselect-dropdown>
                </div>
                <div class="col-sm-3">
                  <ng-multiselect-dropdown *ngIf="aircastleCheck === true" [placeholder]="'Select Marketing Rep'" [ngModelOptions]="{standalone: true}"
                    [data]="mRep" [(ngModel)]="mRepSelected" [settings]="dropdownSettings"
                    (onSelect)="onSelected($event, 'mRep','select')" (onSelectAll)="onSelected($event, 'mRep','select')"
                    (onDeSelect)="onDeselect($event, 'mRep','deselect')"
                    (onDeSelectAll)="onDeselectAll($event, 'mRep','deselectAll')"></ng-multiselect-dropdown>
                </div>
                <div class="col-sm-3 mt-3">
                  <ng-multiselect-dropdown *ngIf="aircastleCheck === true" [placeholder]="'Select Tech Rep'" [data]="techRep" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="techRepSelected" [settings]="dropdownSettings"
                    (onSelect)="onSelected($event, 'techRep','select')" (onSelectAll)="onSelected($event, 'techRep','select')"
                    (onDeSelect)="onDeselect($event, 'techRep','deselect')"
                    (onDeSelectAll)="onDeselectAll($event, 'techRep','deselectAll')"></ng-multiselect-dropdown>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="container-fluid text-dark">
  <div class="row">
    <!-- <div class="col-sm-4 mt-3">
      <input type="number" [(ngModel)]="term2" (keyup)="captureChange($event)" class="form-control" id="search_test"
        placeholder="Type minimum days on ground" style="margin:0px 0px 20px 0;float:left;" autocomplete="off">
    </div> -->
    <!-- <div class="col-sm-8 text-right mt-3">
      <button (click)="exportToCsv()" class="btn btn-success" style="margin:0px 0px 20px 0;">EXPORT 
      </button>
    </div> -->

    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <app-barchart-loader style="height: 400px;" *ngIf="onGroundLoader === false"></app-barchart-loader>
          <div id="AOG-chart"></div>
        </div>
      </div>
    </div>
  </div>
</div>
