<div class="page text-dark">
  <div class="row">
    <div class="col-sm-3">
      <div class="card">
        <div class="card-body">
          <div class="col-sm-12" style="min-height: 388px;">
            <h5 class="text-uppercase" style="font-size: 0.9rem;">Daily flight breakdown</h5>
            <h6 class="mt-3">MSN</h6>
            <div>{{this.tableserviceService.msn}}</div>
            <h6 class="mt-3">Reg</h6>
            <div>{{this.tableserviceService.reg}}</div>
            <h6 class="mt-3">ACTYPE</h6>
            <div>{{this.tableserviceService.acType}}</div>
            <h6 class="mt-3">Operator</h6>
            <div>{{this.tableserviceService.operator}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div id="chartdiv"></div>
    </div>
    <div class="col-sm-12 mt-3">
      <div class="card" *ngIf="this.tableserviceService.tableData?.length === 0">
        <app-table-loader></app-table-loader>
      </div>
      <div class="card" *ngIf="this.tableserviceService.tableData?.length > 0">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <input type="text" [(ngModel)]="term1" class="form-control" id="search_daily" placeholder="Search here" style="margin: 0px 0px 20px 0; float:left;">
            </div>
            <div class="col-sm-8 text-right">
              <div class="excelButton">
                <button (click)="this.tableserviceService.exportToCsv('dailyFlightBreakdown')" class="btn btn-success" >EXPORT</button>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="table-responsive" id ="dailyFlightBreakdown" #table2>
                <table id="table2" class="table">
                  <thead>
                    <tr>
                      <th class="text-uppercase">Registration</th>
                      <th class="text-uppercase">Serial Number</th>
                      <th class="text-uppercase">Operator</th>
                      <th class="text-uppercase">Date</th>
                      <th class="text-uppercase">Start time</th>
                      <th class="text-uppercase">Stop time</th>
                      <th class="text-uppercase">Departure Country</th>
                      <th class="text-uppercase">Departure City</th>
                      <th class="text-uppercase">Departure Airport</th>
                      <th class="text-uppercase">Arrival Country</th>
                      <th class="text-uppercase">Arrival City</th>
                      <th class="text-uppercase">Arrival Airport</th>
                      <th class="text-uppercase text-right">FH</th>
                      <th class="text-uppercase text-right">FC</th>
                      <!--<th>Airport Incursion</th>-->
                      <!-- <th>Info</th> -->              
                    </tr>
                  </thead>
                  <tbody *ngIf="(this.tableserviceService.tableData | filter: term1 | paginate: {id: 'pagination2', itemsPerPage: 200, currentPage: p1 }) as result">
                    <tr *ngFor="let dailytotals of result; let i = index">
                      <td>{{dailytotals.registration}}</td>
                      <td>{{dailytotals.serialNumber}}</td>
                      <td>{{dailytotals.operator}}</td>
                      <td>{{dailytotals.date}}</td>
                      <td>{{dailytotals.startDateTime}}</td>
                      <td>{{dailytotals.stopDateTime}}</td>
                      <td>{{dailytotals.departureCountry}}</td>
                      <td>{{dailytotals.departureCity}}</td>
                      <td>{{dailytotals.departureAirport}}</td>
                      <td>{{dailytotals.arrivalCountry}}</td>
                      <td>{{dailytotals.arrivalCity}}</td>
                      <td>{{dailytotals.arrivalAirport}}</td>
                      <td class="text-right">{{dailytotals.flightHours}}</td>
                      <td class="text-right">{{dailytotals.flightCycles}}</td>
                      <!-- <td>{{dailytotals.lastFlightOfDay}}</td> -->
                      <!-- <td>{{dailytotals.airportIncursion}}</td> -->
                      <!-- <td><button id="button"  [routerLink] = "['/dailyinfo']" type="button" class="btn btn-info" > info</button></td> -->              
                    </tr>
                    <tr *ngIf="result.length === 0" class="no-data-found-tr">
                      <td colspan="8" class="no-data-found-td">No data to display</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <pagination-controls id="pagination2" (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
