import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule } from 'ngx-highlightjs';
import json from 'highlight.js/lib/languages/json';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TableComponent } from './components/table/table.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgxPaginationModule } from 'ngx-pagination'
import { FormsModule } from '@angular/forms';
import { MonthComponent } from './pages/month/month.component';
import { DailyComponent } from './pages/daily/daily.component';
import { DailyTableComponent } from './components/daily-table/daily-table.component';
import { ComparisonComponent } from './pages/comparison/comparison.component';
import { InterceptorService } from './auth/interceptor.service';
import { MonthlyInfoComponent } from './pages/monthly-info/monthly-info.component';
import { DailyInfoComponent } from './pages/daily-info/daily-info.component';
import { CookieService } from 'ngx-cookie-service';
import { DatepickerComponent } from './pages/month/datepicker/datepicker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ButtonViewComponent } from './components/button-view-component/button-view-component.component';
import { TableLoaderComponent } from './components/loaders/table-loader/table-loader.component';
import { CardLoaderComponent } from './components/loaders/card-loader/card-loader.component';
import { BarchartLoaderComponent } from './components/loaders/barchart-loader/barchart-loader.component';
import { MonthlyInsightComponent } from './components/monthly-insight/monthly-insight.component';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InsightComponent } from './pages/insight/insight.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IncidentComponent } from './pages/incident/incident.component';
import { IncidentButtonComponent } from './components/incident-button/incident-button.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { DailyFlownComponent } from './pages/daily-flown/daily-flown.component';



export function hljsLanguages() {
  return [{ name: 'json', func: json }];
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    HomeContentComponent,
    LoadingComponent,
    DashboardComponent,
    TableComponent,
    MonthComponent,
    DailyComponent,
    DailyTableComponent,
    ComparisonComponent,
    MonthlyInfoComponent,
    DailyInfoComponent,
    ButtonViewComponent,
    DatepickerComponent,
    TableLoaderComponent,
    CardLoaderComponent,
    BarchartLoaderComponent,
    MonthlyInsightComponent,
    InsightComponent,
    IncidentComponent,
    IncidentButtonComponent,
    TooltipComponent,
    DailyFlownComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScrollingModule,
    NgbModule,
    HighlightModule.forRoot({
      languages: hljsLanguages
    }),
    FontAwesomeModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MultiSelectModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2SmartTableModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    ButtonViewComponent,
    IncidentButtonComponent,
    TooltipComponent
 ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
