import { Component, OnInit } from '@angular/core';
import config from './../../../../configuration.json'
import { HttpClient } from '@angular/common/http';
import * as Highcharts from 'highcharts';
import * as Highstock from "highcharts/highstock";
import { TableserviceService } from '../../services/tableservice.service';
import HC_exporting from 'highcharts/modules/exporting';
import { AUTO_STYLE } from '@angular/animations';
import { ExportToCsv } from 'export-to-csv';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthService } from '../../auth/auth.service';
HC_exporting(Highcharts);

declare var $: any;
@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent implements OnInit {
  hex;
  serialNumber;
  public dailytotalsAOG: any = [];
  public onGroundArrayFinal: any = {};
  arrayFinal: any = [];
  daysOnGroundArr = [];
  serialNumbersArr = [];
  finalNewDogArr = [];
  term2;
  finalAircraftOnGroundArr = [];
  onGroundLoader: boolean = false; // Bar chart loader
  // new 
  dailytotals: any = [];
  aircastleCheck: boolean;
  organisation: string;
  masterDaysFlownData: any = [];
  finalTableData: any = [];
  finalTableData1: any = [];
  testarr = [];
  dateRange: any;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dailyTableLoader: boolean = true;
  finalList = {};
  flown: any = {};
  serialNumberNew: any = [];
  headers = [];
  operator: any = [];
  assetType: any = [];
  assetModelSeries: any = [];
  mRep: any = [];
  techRep: any = [];
  operatorSelected: any = [];
  assetTypeSelected: any = [];
  assetModelSeriesSelected: any = [];
  mRepSelected: any = [];
  techRepSelected: any = [];
  daysOnGroundSelected: number = null;
  filterOrder = [];
  firstFilter = [];
  masterAssetDetails: any = [];
  dropdownSettings: IDropdownSettings;
  parent: any;
  aircraftCount: any;
  engineCount: any;
  charts: any;
  chartTitle: any;
  public temp_operators = [];
  public temp_assetModelSeries = [];
  public temp_assetType = [];
  public temp_mRep = [];
  public temp_techRep = [];
  public msnArray = [];


  constructor(private httpClient: HttpClient, private tableService: TableserviceService, public authService: AuthService,) { }


  ngOnInit() {
    // console.log("In insights component...");
    // Retrieve all aircaft assets for paticular Lessor
    this.organisation = this.authService.organisation;
    // console.log(this.organisation);
    if (this.organisation == 'aircastle') {
      this.aircastleCheck = true;
    } else {
      this.aircastleCheck = false;
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.aircraftCount = 0;
    this.engineCount = 0;
    this.operatorSelected = [];
    this.assetModelSeriesSelected = [];
    this.assetTypeSelected = [];
    this.mRepSelected = [];
    this.techRepSelected = [];
    //this.daysOnGroundSelected = null;
    this.filterOrder = [];
    let __this = this;
    this.httpClient.get(config.apiUrl + '/uniform/clientdetails/undefined/')
      .toPromise()
      .then((response: any) => {
        //console.log("Returned", response);
        // console.log(assetList);

        if (response.response === 200) {
          let assetList = response.data;
          let serialNumber = [];
          for (let i = 0; i < assetList.length; i++) {
            // console.log(i);
            //hex.push(assetList[i].hex)
            serialNumber.push(assetList[i].serialNumber);
          }
          __this.serialNumber = serialNumber;
          __this.calculateTimeOnGround(serialNumber);
        }
      });
  }

  exportToCsv() {
    var filename = "AircraftOnGround"
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: filename,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.firstFilter);
  }

  calculateTimeOnGround(serialNumber) {
    //console.log("Calculating time on ground");
    let __this = this;
    return this.httpClient.post(config.apiUrl + '/uniform/report/aircraftonground', serialNumber)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let res = response.data;
          // console.log('res');
          // console.log("get AOG FLAG 1Response");
          //   console.log(res);
          __this.dailytotalsAOG = [];
          for (let k = 0; k < res.length; k++) {
            if (res[k].length > 0) {
              __this.dailytotalsAOG.push(res[k]);

            }
          }
          let date: Date = new Date();
          for (let x = 0; x < __this.dailytotalsAOG.length; x++) {
            let newDate = new Date(__this.dailytotalsAOG[x][0].stopDateTime);
            if (!__this.dailytotalsAOG[x][0].stopDateTime) {
              __this.dailytotalsAOG[x].push({ "daysOnGround": "-" });
            }
            // console.log("AOG NULL VALUES REMOVED")
            //console.log('AOG');
            //console.log(__this.dailytotalsAOG);
            let date: Date = new Date();
            for (let x = 0; x < __this.dailytotalsAOG.length; x++) {
              let newDate = new Date(__this.dailytotalsAOG[x][0].stopDateTime);
              if (!__this.dailytotalsAOG[x][0].stopDateTime) {
                __this.dailytotalsAOG[x].push({ "daysOnGround": "-" });
              } else {
                let diff = Math.abs(date.getTime() - newDate.getTime());
                //console.log(diff);
                let daysOnGround = Math.floor(diff / (1000 * 3600 * 24));
                __this.dailytotalsAOG[x].push({ "daysOnGround": daysOnGround });
              }
              //console.log(newDate);
              //console.log(date.getTime());
              //console.log(newDate.getTime());
            }
            //console.log(newDate);
            //console.log(date.getTime());
            //console.log(newDate.getTime());
          }
          //console.log("AOG inserted into AOG REFINED ARRAY");
          __this.firstFilter = [];
          __this.serialNumbersArr = [];
          __this.daysOnGroundArr = [];
          // console.log(__this.firstFilter);
          // console.log(__this.dailytotalsAOG);


          for (let keys in __this.dailytotalsAOG) {

            let serialNumber = __this.dailytotalsAOG[keys][0].serialNumber;
            __this.onGroundArrayFinal[serialNumber] = __this.dailytotalsAOG[keys][0];
            __this.onGroundArrayFinal[serialNumber].daysOnGround = __this.dailytotalsAOG[keys][1].daysOnGround;
            //console.log(__this.onGroundArrayFinal);
            //console.log(__this.onGroundArrayFinal[serialNumber]);
            if (__this.onGroundArrayFinal[serialNumber].daysOnGround > 75) {
              let finalNewDog: any = {};
              finalNewDog.serialNumbers = __this.onGroundArrayFinal[serialNumber].serialNumber;
              finalNewDog.registration = __this.onGroundArrayFinal[serialNumber].registration;
              finalNewDog.assetModelSeries = __this.onGroundArrayFinal[serialNumber].assetModelSeries;
              finalNewDog.operator = __this.onGroundArrayFinal[serialNumber].operator
              finalNewDog.daysOnGround = __this.onGroundArrayFinal[serialNumber].daysOnGround;
              //finalNewDog.arrivalCity = __this.onGroundArrayFinal[serialNumber].arrivalCity;
              finalNewDog.airport = __this.onGroundArrayFinal[serialNumber].arrivalAirport + ', ' + __this.onGroundArrayFinal[serialNumber].arrivalCity + ', ' + __this.onGroundArrayFinal[serialNumber].arrivalCountry;
              finalNewDog.assetType = __this.onGroundArrayFinal[serialNumber].assetType;
              __this.firstFilter.push(finalNewDog);
              __this.serialNumbersArr.push(__this.onGroundArrayFinal[serialNumber].serialNumber);
              __this.daysOnGroundArr.push(__this.onGroundArrayFinal[serialNumber].daysOnGround);
            }
          }
          __this.arrayFinal = [];
          for (let key in __this.onGroundArrayFinal) {
            let objectFinal: any = {}
            objectFinal.serialNumber = __this.onGroundArrayFinal[key].serialNumber;
            objectFinal.registration = __this.onGroundArrayFinal[key].registration;
            objectFinal.assetModelSeries = __this.onGroundArrayFinal[key].assetModelSeries;
            objectFinal.operator = __this.onGroundArrayFinal[key].operator
            objectFinal.daysOnGround = __this.onGroundArrayFinal[key].daysOnGround;
            objectFinal.airport = __this.onGroundArrayFinal[key].arrivalAirport + ', ' + __this.onGroundArrayFinal[key].arrivalCity  + ', ' + __this.onGroundArrayFinal[key].arrivalCountry;
            //objectFinal.arrivalCountry = __this.onGroundArrayFinal[key].arrivalCountry;
            objectFinal.assetType = __this.onGroundArrayFinal[key].assetType;
            if (this.organisation == 'aircastle') {
              objectFinal.marketingRep = __this.onGroundArrayFinal[key].marketingRep;
              objectFinal.techRep = __this.onGroundArrayFinal[key].techRep;
            }
            __this.arrayFinal.push(objectFinal);
          }

          this.operatorSelected = [];
          this.assetModelSeriesSelected = [];
          this.assetTypeSelected = [];
          this.mRepSelected = [];
          this.techRepSelected = [];
          this.daysOnGroundSelected = null;
          this.filterOrder = [];

          __this.masterAssetDetails = __this.arrayFinal;
          __this.initialFilterPrep(__this.masterAssetDetails);
          //console.log(__this.firstFilter);
          //console.log(__this.dailytotalsAOG);


          __this.chartTitle = " Greater than 75 Days"
          let aircraftCount = __this.firstFilter.filter((obj) => obj.assetType === "Aircraft").length;
          //console.log(aircraftCount);
          let engineCount = __this.firstFilter.filter((obj) => obj.assetType === "Engine").length;
          __this.aircraftCount = aircraftCount;
          __this.engineCount = engineCount;
          // __this.serialNumbersArr.sort();
          // __this.daysOnGroundArr.sort();

          
          
          __this.graphAOG(__this.serialNumbersArr, __this.daysOnGroundArr);
        }
      });
  }


  graphAOG(serialNumbers: any, timeOnGroundAr: any) {

    let __this = this;
    let categories = serialNumbers;
    __this.charts = Highcharts.chart('AOG-chart', {
      chart: {
        type: 'bar',
        events: {
          load: function () {
            __this.onGroundLoader = true;
          }
        },
      },
      title: {
        text: 'AOG'
      },
      subtitle: {
        text: 'Aircraft on Ground'
      },
      xAxis: {
        categories: categories,
        title: {
          text: 'Serial Number'
        }, 
        labels: {
          step: 1
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value) + ' days';
          }
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 750

          },
          chartOptions: {
            legend: {
              enabled: false
            },
          }
        }]
      },
      tooltip: {
        valueSuffix: 'days',
        formatter: function () {
          let msnObject = __this.firstFilter[this.point.index];
          return '<b>Registration: ' + msnObject['registration'] + '</b><br/>' +
            '<b>Serial Number: ' + this.point.category + '</b><br/>' +
            '<b>Days on ground: ' + this.point.y + '</b><br/>' +
            '<b>Asset Model Series: ' + msnObject['assetModelSeries'] + '</b><br/>' +
            '<b>Operator: ' + msnObject['operator'] + '</b><br/>' +
            '<b>Airport: ' + msnObject['airport'] + '</b><br/>';
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
        name: __this.chartTitle,
        type: undefined,
        data: timeOnGroundAr
      }]
    });
    let dynamicHeight = serialNumbers.length * 20;
    if (dynamicHeight < 500) {
      dynamicHeight = 500;
      __this.charts.setSize((document.getElementById('AOG-chart') as HTMLElement).offsetWidth - 25, dynamicHeight, false);
    } else {
      __this.charts.setSize((document.getElementById('AOG-chart') as HTMLElement).offsetWidth - 25, dynamicHeight, false);
    }
  }




  onSelected(val: any, target, action) {
    // console.log("SELECTED : "+target);

    // console.log("On Selected: " +this.operatorSelected);
    // console.log("On Selected: " +this.assetModelSeriesSelected);
    // console.log("On Selected: " +this.assetTypeSelected);
    // console.log("On Selected: " +this.mRepSelected);
    // console.log("On Selected: " +this.techRepSelected);
    // console.log('Inside OnSelected Function');
    let __this = this;
    if (action == 'select') {
      if (!(__this.filterOrder.indexOf(target) >= 0)) {
        __this.filterOrder.push(target);
      }
    }
    let operatorSelected = __this.operatorSelected;
    let assetTypeSelected = __this.assetTypeSelected;
    let assetModelSeriesSelected = __this.assetModelSeriesSelected;
    let mRepSelected = __this.mRepSelected;
    let techRepSelected = __this.techRepSelected;
    let daysOnGroundSelected = __this.daysOnGroundSelected;
    //console.log(daysOnGroundSelected);
    __this.firstFilter = __this.masterAssetDetails.filter((result: any) => {
      if (operatorSelected.length) {
        let isPresent = operatorSelected.includes(result.operator);
        if (isPresent) {
          return result;
        }
      } else {
        return __this.dailytotals;
      }
    }).filter((result1: any) => {
      if (assetTypeSelected.length) {
        let isPresent = assetTypeSelected.includes(result1.assetType);
        if (isPresent) {
          return result1;
        }
      } else {
        return result1;
      }
    }).filter((result2: any) => {
      if (assetModelSeriesSelected.length) {
        let isPresent = assetModelSeriesSelected.includes(result2.assetModelSeries);
        if (isPresent) {
          return result2;
        }
      } else {
        return result2;
      }
    }).filter((result3: any) => {
      if (mRepSelected.length) {
        let isPresent = mRepSelected.includes(result3.marketingRep);
        if (isPresent) {
          return result3;
        }
      } else {
        return result3;
      }
    }).filter((result4: any) => {
      if (techRepSelected.length) {
        let isPresent = techRepSelected.includes(result4.techRep);
        if (isPresent) {
          return result4;
        }
      } else {
        return result4;
      }
    }).filter((result5: any) => {
      //console.log(__this.daysOnGroundSelected);
      let requestedDaysOnGround;
      if (daysOnGroundSelected > 0) {
        requestedDaysOnGround = daysOnGroundSelected;
        //console.log(daysOnGroundSelected);
      } else if (techRepSelected.length === 0 && mRepSelected.length === 0 && assetModelSeriesSelected.length === 0 && assetTypeSelected.length === 0 && operatorSelected.length === 0) {
        requestedDaysOnGround = 75;
        __this.chartTitle = "Greater than 75 Days"
        //return result5;
      }
      if (requestedDaysOnGround > 0) {
        if (result5.daysOnGround > requestedDaysOnGround) {
          //console.log('inside if loop Days on Ground');
          __this.chartTitle = "Greater than " + requestedDaysOnGround + " Days"
          return result5
        }
      } else {
        //console.log('inside else DaysGround');
        __this.chartTitle = "Greater than 0 Day"
        return result5;
      }
    })
    // if (daysOnGroundSelected == null) {
    //   console.log('Null Value');
    // }
    __this.serialNumbersArr = [];
    __this.finalNewDogArr = [];
    __this.daysOnGroundArr = [];
    //console.log('serialNumberArr:',__this.serialNumbersArr);
    for (let keyss in __this.firstFilter) {
      for (let keyyss in __this.onGroundArrayFinal) {
        if (__this.firstFilter[keyss]['serialNumber'] == __this.onGroundArrayFinal[keyyss].serialNumber) {
          //console.log(__this.onGroundArrayFinal[keyyss].serialNumber);   
          let finalNewDog: any = {};
          finalNewDog.serialNumbers = __this.onGroundArrayFinal[keyyss].serialNumber;
          finalNewDog.registration = __this.onGroundArrayFinal[keyyss].registration;
          finalNewDog.assetModelSeries = __this.onGroundArrayFinal[keyyss].assetModelSeries;
          finalNewDog.operator = __this.onGroundArrayFinal[keyyss].operator
          finalNewDog.daysOnGround = __this.onGroundArrayFinal[keyyss].daysOnGround;
          //finalNewDog.arrivalCountry = __this.onGroundArrayFinal[keyyss].arrivalCountry;
          finalNewDog.airport = __this.onGroundArrayFinal[keyyss].arrivalAirport + ', ' +__this.onGroundArrayFinal[keyyss].arrivalCity + ', ' + __this.onGroundArrayFinal[keyyss].arrivalCountry;
          finalNewDog.assetType = __this.onGroundArrayFinal[keyyss].assetType;
          __this.finalNewDogArr.push(finalNewDog);
          __this.serialNumbersArr.push(__this.onGroundArrayFinal[keyyss].serialNumber);
          __this.daysOnGroundArr.push(__this.onGroundArrayFinal[keyyss].daysOnGround);
        }
      }
    }
    

    if (techRepSelected.length === 0 && mRepSelected.length === 0 && assetModelSeriesSelected.length === 0 && assetTypeSelected.length === 0 && operatorSelected.length === 0 && daysOnGroundSelected === null) {
      __this.firstFilter = [];
      for (let key in __this.onGroundArrayFinal) {
        let objectFinal: any = {}
        objectFinal.serialNumber = __this.onGroundArrayFinal[key].serialNumber;
        objectFinal.registration = __this.onGroundArrayFinal[key].registration;
        objectFinal.assetModelSeries = __this.onGroundArrayFinal[key].assetModelSeries;
        objectFinal.operator = __this.onGroundArrayFinal[key].operator
        objectFinal.daysOnGround = __this.onGroundArrayFinal[key].daysOnGround;
        //objectFinal.arrivalCountry = __this.onGroundArrayFinal[key].arrivalCountry;
        objectFinal.airport = __this.onGroundArrayFinal[key].arrivalAirport + ', ' + __this.onGroundArrayFinal[key].arrivalCity + ', ' + __this.onGroundArrayFinal[key].arrivalCountry;
        objectFinal.assetType = __this.onGroundArrayFinal[key].assetType;
        if (this.organisation == 'aircastle') {
          objectFinal.marketingRep = __this.onGroundArrayFinal[key].marketingRep;
          objectFinal.techRep = __this.onGroundArrayFinal[key].techRep;
        }
        __this.firstFilter.push(objectFinal);
      }
    }
    let aircraftCount = __this.finalNewDogArr.filter((obj) => obj.assetType === "Aircraft").length;
    let engineCount = __this.finalNewDogArr.filter((obj) => obj.assetType === "Engine").length;
    __this.aircraftCount = aircraftCount;
    __this.engineCount = engineCount;

    __this.graphAOG(__this.serialNumbersArr, __this.daysOnGroundArr);
    // __this.tablePrep(firstFilter);
    __this.dropDownPrep(__this.firstFilter, this.filterOrder);


  }

  onDeselect(val: any, target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch (target) {
      case 'operator':
        if (!this.operatorSelected.length) {
          //deselect all -- the filter values are null
          for (let i = 0; i < this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'operator') {
              this.filterOrder.splice(i, 1);
            }
          } this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'assetType':
        if (!this.assetTypeSelected.length) {
          //deselect all -- the filter values are null
          for (let i = 0; i < this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetType') {
              this.filterOrder.splice(i, 1);
            }
          }
          this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'assetModelSeries':
        if (!this.assetModelSeriesSelected.length) {
          for (let i = 0; i < this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetModelSeries') {
              this.filterOrder.splice(i, 1);
            }
          } this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'mRep':
        if (!this.mRepSelected.length) {
          for (let i = 0; i < this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'mRep') {
              this.filterOrder.splice(i, 1);
            }
          }
          this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'techRep':
        if (!this.techRepSelected.length) {
          for (let i = 0; i < this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'techRep') {
              this.filterOrder.splice(i, 1);
            }
          } this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
    }
  }
  onDeselectAll(val: any, target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.flightHoursSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch (target) {
      case 'operator':
        this.operatorSelected = [];
        for (let i = 0; i < this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'operator') {
            this.filterOrder.splice(i, 1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'assetType':
        this.assetTypeSelected = [];
        for (let i = 0; i < this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'assetType') {
            this.filterOrder.splice(i, 1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'assetModelSeries':
        this.assetModelSeriesSelected = [];
        for (let i = 0; i < this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'assetModelSeries') {
            this.filterOrder.splice(i, 1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'mRep':
        this.mRepSelected = [];
        for (let i = 0; i < this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'mRep') {
            this.filterOrder.splice(i, 1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'techRep':
        this.techRepSelected = [];
        for (let i = 0; i < this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'techRep') {
            this.filterOrder.splice(i, 1);
          }
        }
        this.onSelected(val, target, action);
        break;
    }
  }

  dropDownPrep(assetData: any, filterArray: any) {
    // console.log("ASSET DATA: ");
    // console.log(assetData);
    // console.log("Filter Array :", filterArray);

    let __this = this;
    __this.tablePrep(assetData);
    __this.parent = filterArray[0];
    if (filterArray.indexOf('operator') < 0 || !filterArray[filterArray.indexOf('operator') + 1] == null) {
      __this.temp_operators = [];
    }

    if (filterArray.indexOf('assetModelSeries') < 0 || !filterArray[filterArray.indexOf('assetModelSeries') + 1] == null) {
      __this.temp_assetModelSeries = [];
    }

    if (filterArray.indexOf('mRep') < 0 || !filterArray[filterArray.indexOf('mRep') + 1] == null) {
      __this.temp_mRep = [];
    }

    if (filterArray.indexOf('techRep') < 0 || !filterArray[filterArray.indexOf('techRep') + 1] == null) {
      __this.temp_techRep = [];
    }

    if (filterArray.indexOf('assetType') < 0 || !filterArray[filterArray.indexOf('assetType') + 1] == null) {

      __this.temp_assetType = [];
    }

    for (let i = 0; i < assetData.length; i++) {
      if (!(__this.temp_operators.indexOf(assetData[i].operator) >= 0) && __this.parent != 'operator') {
        __this.temp_operators.push(assetData[i].operator);
      }
      if (!(__this.temp_assetType.indexOf(assetData[i].assetType) >= 0) && __this.parent != 'assetType') {
        __this.temp_assetType.push(assetData[i].assetType);
      }
      if (!(__this.temp_assetModelSeries.indexOf(assetData[i].assetModelSeries) >= 0) && __this.parent != 'assetModelSeries') {
        __this.temp_assetModelSeries.push(assetData[i].assetModelSeries);
      }
      if (!(__this.temp_mRep.indexOf(assetData[i].marketingRep) >= 0) && __this.parent != 'mRep') {
        __this.temp_mRep.push(assetData[i].marketingRep);
      }
      if (!(__this.temp_techRep.indexOf(assetData[i].techRep) >= 0) && __this.parent != 'techRep') {
        __this.temp_techRep.push(assetData[i].techRep);
      }
    }



    for (let i = 0; i < __this.masterAssetDetails.length; i++) {
      switch (__this.parent) {
        case 'operator':
          if (!(__this.temp_operators.indexOf(__this.masterAssetDetails[i].operator) >= 0)) {
            __this.temp_operators.push(__this.masterAssetDetails[i].operator);
          }
          break;
        case 'assetModelSeries':
          if (!(__this.temp_assetModelSeries.indexOf(__this.masterAssetDetails[i].assetModelSeries) >= 0)) {
            __this.temp_assetModelSeries.push(__this.masterAssetDetails[i].assetModelSeries);
          }
          break;
        case 'mRep':
          if (!(__this.temp_mRep.indexOf(__this.masterAssetDetails[i].marketingRep) >= 0)) {
            __this.temp_mRep.push(__this.masterAssetDetails[i].marketingRep);
          }
          break;
        case 'techRep':
          if (!(__this.temp_techRep.indexOf(__this.masterAssetDetails[i].techRep) >= 0)) {
            __this.temp_techRep.push(__this.masterAssetDetails[i].techRep);
          }
          break;
        case 'assetType':
          if (!(__this.temp_assetType.indexOf(__this.masterAssetDetails[i].assetType) >= 0)) {
            __this.temp_assetType.push(__this.masterAssetDetails[i].assetType);
          }
          break;
      }
    }

    __this.operator = __this.temp_operators.sort((one, two) => (one > two ? 1 : -1));
    __this.assetType = __this.temp_assetType.sort((one, two) => (one > two ? 1 : -1));
    __this.assetModelSeries = __this.temp_assetModelSeries.sort((one, two) => (one > two ? 1 : -1));
    __this.mRep = __this.temp_mRep.sort((one, two) => (one > two ? 1 : -1));
    __this.techRep = __this.temp_techRep.sort((one, two) => (one > two ? 1 : -1));

    // console.log("FINAL DROPDOWN VALUES");
    // console.log(__this.operator);
    // console.log(__this.assetType);
    // console.log(__this.assetModelSeries);
    // console.log(__this.mRep);
    // console.log(__this.techRep);
  }

  tablePrep(serialNumberArray: any) {
    let __this = this;
    let msnArr = [];
    let masterTableData = __this.masterDaysFlownData;

    for (let i = 0; i < serialNumberArray.length; i++) {
      msnArr.push(serialNumberArray[i].serialNumber); //changed from serialClient to serial Number
    }
    __this.msnArray = msnArr;
    let temp = {};
    for (let i = 0; i < msnArr.length; i++) {
      temp[msnArr[i]] = masterTableData[msnArr[i]];
    }
    __this.finalTableData = temp;
  }


  initialFilterPrep(masterAssetData) {
    // console.log(masterAssetData);
    let operator = [];
    let assetModelSeries = [];
    let assetType = [];
    let techRep = [];
    let mRep = [];
    // console.log("In FILTER PREP");
    let __this = this;
    for (let i = 0; i < masterAssetData.length; i++) {
      if (!(operator.indexOf(masterAssetData[i].operator) >= 0)) {
        operator.push(masterAssetData[i].operator);
      }
      if (!(assetModelSeries.indexOf(masterAssetData[i].assetModelSeries) >= 0)) {
        assetModelSeries.push(masterAssetData[i].assetModelSeries);
      }
      if (!(assetType.indexOf(masterAssetData[i].assetType) >= 0)) {
        assetType.push(masterAssetData[i].assetType);
      }
      if (!(techRep.indexOf(masterAssetData[i].techRep) >= 0)) {
        techRep.push(masterAssetData[i].techRep);
      }
      if (!(mRep.indexOf(masterAssetData[i].marketingRep) >= 0)) {
        mRep.push(masterAssetData[i].marketingRep);
      }
    }

    __this.operator = operator;
    __this.assetModelSeries = assetModelSeries;
    __this.assetType = assetType;
    __this.techRep = techRep;
    __this.mRep = mRep;

  }
}
