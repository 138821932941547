
<div class="container-fluid text-dark">
  <div class="row mt-3">
    <div class="col-sm-12">
      <div class="buttonContainer">
        <ul class="nav nav-pills" role="tablist">
          <li class="nav-item" role="presentation" (click)="showPanel('monthly-insights-self-serve')">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'monthly-insights-self-serve'}" id="monthly-insight-tab" data-toggle="tab" href="javascript:;" role="tab" aria-controls="home" aria-selected="true">Report Builder</a>
          </li>
          <li class="nav-item" role="presentation" (click)="showPanel('aircraft-on-ground')">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'aircraft-on-ground'}" id="comparison-tab" data-toggle="tab" href="javascript:;" role="tab" aria-controls="profile" aria-selected="false">Aircraft On Ground</a>
          </li>
          <li class="nav-item" role="presentation" (click)="showPanel('daily-flown-report')">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'daily-flown-report'}" id="daily-flown-tab" data-toggle="tab" href="javascript:;" role="tab" aria-controls="profile" aria-selected="false">Days Flown Report</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="tab-content col-sm-12">
      <div class="tab-pane fade" [ngClass]="{'active show': activeTab === 'monthly-insights-self-serve'}" role="tabpanel" aria-labelledby="monthly-insight-tab">
        <app-monthly-insight></app-monthly-insight>
      </div>
      <div class="tab-pane fade" [ngClass]="{'active show': activeTab === 'aircraft-on-ground'}" id="comparison" role="tabpanel" aria-labelledby="comparison-tab">
        <app-comparison *ngIf = "activeTab === 'aircraft-on-ground'"></app-comparison>
      </div>
      <div class="tab-pane fade" [ngClass]="{'active show': activeTab === 'daily-flown-report'}" id="dailyflown" role="tabpanel" aria-labelledby="daily-flown-tab">
        <app-daily-flown *ngIf = "activeTab === 'daily-flown-report'"></app-daily-flown>
      </div>
    </div>
  </div>
</div>
