<div>
  <!-- Header starts -->
    <app-nav-bar></app-nav-bar>
  <!-- Header ends -->
  <!-- Main content start -->
    <router-outlet></router-outlet>
  <!-- Main content ends -->
  <!-- Footer starts -->
    <app-footer></app-footer>
  <!-- Footer ends -->
</div>

<!-- 
<div class="d-flex flex-column h-100">
  <app-nav-bar></app-nav-bar>

  <div class="flex-grow-1 flex-shrink-1">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div> -->
