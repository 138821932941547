import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.css']
})
export class InsightComponent implements OnInit {
  activeTab: string = 'monthly-insights-self-serve';
  constructor() { }

  ngOnInit() {}

  showPanel(activeTab: string) {
    this.activeTab = activeTab;
  }
}
