<button class="btn btn-info" (click)="open(mymodal)">INFO</button>

<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
              <iframe [src]= "this.iframeURI" title="description" class = "modal-iframe" id ="iframe"frameborder="0"></iframe>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div> -->
  </ng-template>
