<ng-template #popContent>
  <div class="content-title">Crash:</div>
  <div class="content-body">An article marked as Crash is an accident which has the potential of being catastrophic.
    Also known as plane crash or airplane crash.
  </div>
  <div class="content-title">Accident:</div>
  <div class="content-body">An article marked as Accident is a safety incident which causes injuries or even death to
    persons on board or on ground and/or causes greater damage to the airplane. Could also include people injured by
    fumes in the cabin.
  </div>
  <div class="content-title">Incident:</div>
  <div class="content-body">An article marked as Incident is an occurrence in commercial aviation which is safety
    relevant but causes no direct harm or injury to any person on board and/or causing only limited damage to the
    airplane.
  </div>
  <div class="content-title">Report:</div>
  <div class="content-body">
    An article marked as Report details official releases about an incident or an accident.
  </div>
  <div class="content-title">News:</div>
  <div class="content-body">
    An article marked as News is about any other aviation events.
  </div>
</ng-template>
<button type="button" class="incType" [ngbPopover]="popContent" triggers="mouseenter:mouseleave"
  popoverTitle="Info on Incident Type">
  {{ this.renderValue }}
</button>