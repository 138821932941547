<div class="row">
  <div class="Iam col-sm-12">
    <p><b>Utilisation</b></p>
    <b>
      <div class="innerIam">
        2.0<br>
        oversight<br>
        awareness<br>
        precision<br> 
      </div>
    </b>
  </div>
  <div class="col-sm-12">
    <div class="fixed-bottom text-center mb-3">
      <img src="../../../assets/B_Logo_Colour.png" width="120" >
    </div>
  </div>
</div>
