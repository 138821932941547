<svg
  role="img"
  width="100%"
  height="400"
  aria-labelledby="loading-aria-barchart"
  viewBox="0 0 360 400"
  preserveAspectRatio="none"
>
  <title id="loading-aria-barchart">Loading...</title>
  <rect
    x="0"
    y="0"
    width="100%"
    height="100%"
    clip-path="url(#clip-path-barchart)"
    style='fill: url("#fill-barchart");'
  ></rect>
  <defs>
    <clipPath id="clip-path-barchart">
        <rect x="0" y="160" rx="0" ry="0" width="20" height="400" />
        <rect x="30" y="145" rx="0" ry="0" width="20" height="400" />
        <rect x="60" y="126" rx="0" ry="0" width="20" height="400" />
        <rect x="90" y="80" rx="0" ry="0" width="20" height="400" />
        <rect x="120" y="142" rx="0" ry="0" width="20" height="400" />
        <rect x="150" y="80" rx="0" ry="0" width="20" height="400" />
        <rect x="180" y="60" rx="0" ry="0" width="20" height="400" />
        <rect x="210" y="120" rx="0" ry="0" width="20" height="400" />
        <rect x="240" y="142" rx="0" ry="0" width="20" height="400" />
        <rect x="270" y="100" rx="0" ry="0" width="20" height="400" />
        <rect x="300" y="133" rx="0" ry="0" width="20" height="400" />
        <rect x="330" y="80" rx="0" ry="0" width="20" height="400" />
    </clipPath>
    <linearGradient id="fill-barchart">
      <stop
        offset="0.599964"
        stop-color="#f3f3f3"
        stop-opacity="1"
      >
        <animate
          attributeName="offset"
          values="-2; -2; 1"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop
        offset="1.59996"
        stop-color="#ecebeb"
        stop-opacity="1"
      >
        <animate
          attributeName="offset"
          values="-1; -1; 2"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop
        offset="2.59996"
        stop-color="#f3f3f3"
        stop-opacity="1"
      >
        <animate
          attributeName="offset"
          values="0; 0; 3"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
    </linearGradient>
  </defs>
</svg>