import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
// import * as jwt from 'jsonwebtoken';
import * as jwt_decode from 'jwt-decode';
import config from '../../../configuration.json';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {
  // console.log("in constructor");
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        // console.log("Im here");
        // console.log(token);
        var decoded = jwt_decode(token);
        // console.log("decoded");
        // console.log(decoded);
        let production = config.production
        // console.log("In interceptor. Running in production: " + production);
        if (decoded.permissions[0] == "blockaviation") {
          console.log("Organistion is Blockaviation setting permissions to Aircastle for demo purposes...");
          decoded.permissions[0] = "aircastle";
        }
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}`, View: token, production: production }
        });
        return next.handle(tokenReq);
      }),
      catchError(err => throwError(err))
    );
  }
}
