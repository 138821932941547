import { Component, OnInit } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormControl, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import config from '../../../../configuration.json'
import { TableserviceService } from '../../services/tableservice.service';
import { AuthService } from '../../auth/auth.service';
declare var $: any;

// import {NgbdDatepickerRangeModule} from './datepicker/datepicker-range.module';
@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.css']
})
export class MonthComponent implements OnInit {
  dateRange: string;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  startMonth: string;
  startNewMonth: string;
  endMonth: string;
  endNewMonth: string;
  smartTableValues: any;
  organisation: any;
  assetType: string = '';
  operator: string = '';
  aircraftType: string = '';
  techRep: string = '';
  marketingRep: string = '';
  getOrganisation: string = '';


  constructor(
    private tableService: TableserviceService,
    private formBuilder: FormBuilder,
    public httpClient: HttpClient,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.organisation = this.authService.organisation;
    localStorage.setItem('getOrganisation',this.organisation);
    this.getOrganisation = localStorage.getItem('getOrganisation')
 
    let currentDate = new Date();
    let currentDateStr = currentDate.toString();
    let lastDayOfMonth = new Date();
    let lastDay = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 0);
    let lastDayString = lastDay.toString();
    let year = currentDateStr.split(' ')[3];
    // console.log(year);
    let month = currentDateStr.split(' ')[1];
    // console.log(month);
    let day = lastDayString.split(' ')[2];
    this.dateRange = day + '-' + this.months[currentDate.getMonth()] + '-' + currentDate.getFullYear();
    // console.log(day);
    // console.log('Date Range', this.dateRange);

    this.startMonth = day + '-' + month + '-' + year;
    this.startNewMonth = month + ' ' + year;
    // console.log('startMonth', this.startMonth);

    this.endMonth = day + '-' + month + '-' + year;
    this.endNewMonth = month + ' ' + year;
    // console.log('endMonth', this.endMonth);

  }
  ngAfterViewInit() {
    let __this = this;

    $(".startDate").datepicker({
      autoclose: true,
      format: 'M-yyyy',
      startView: "months",
      minViewMode: "months",
      endDate: '+0d'
    }).on('changeDate', function (selected) {
      //var __this = this;
      var startMonth = new Date(selected.date);
      var startMonthStr = startMonth.toString();
      let month = startMonthStr.split(' ')[1]
      let year = startMonthStr.split(' ')[3]
      let lastDay = new Date(startMonth.getFullYear(), startMonth.getMonth() + 1, 0);
      let lastDayString = lastDay.toString();
      let day = lastDayString.split(' ')[2];
      __this.startMonth = day + '-' + month + '-' + year;
      __this.startNewMonth = month + ' ' + year;
      startMonth.setDate(startMonth.getDate() + 1);
      $('.endDate').datepicker('setStartDate', startMonth);
    });

    $(".endDate").datepicker({
      autoclose: true,
      format: 'M-yyyy',
      startView: "months",
      minViewMode: "months",
      endDate: '+0d'
    }).on('changeDate', function (selected) {
      var endMonth = new Date(selected.date);
      var endMonthStr = endMonth.toString();
      let month = endMonthStr.split(' ')[1]
      let year = endMonthStr.split(' ')[3]
      let lastDay = new Date(endMonth.getFullYear(), endMonth.getMonth() + 1, 0);
      let lastDayString = lastDay.toString();
      let day = lastDayString.split(' ')[2];
      __this.endMonth = day + '-' + month + '-' + year;
      __this.endNewMonth = month + ' ' + year;
      // console.log(__this.endMonth)
    });
  }
  clearFilters() {
    let __this = this;
    __this.aircraftType = '';
    __this.assetType = '';
    __this.operator = '';
    __this.techRep = '';
    __this.marketingRep = '';

    let currentDate = new Date();
    let currentDateStr = currentDate.toString();
    let lastDayOfMonth = new Date();
    let lastDay = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 0);
    let lastDayString = lastDay.toString();
    let year = currentDateStr.split(' ')[3];
    let month = currentDateStr.split(' ')[1];
    let day = lastDayString.split(' ')[2];
    this.dateRange = day + '-' + this.months[currentDate.getMonth()] + '-' + currentDate.getFullYear();
    this.startMonth = day + '-' + month + '-' + year;
    this.startNewMonth = month + ' ' + year;
    this.endMonth = day + '-' + month + '-' + year;
    this.endNewMonth = month + ' ' + year;
    
    __this.smartTableValues = {};
    __this.tableService.monthlyTotalsLoader = true; // Show loader
    this.httpClient.post(config.apiUrl + '/uniform/monthlytotalss/undefined/' + __this.startMonth + '/' + __this.endMonth, __this.smartTableValues)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let monthlytotals = response.data;
          //console.log('response Data from month Component', monthlytotals);
          for (var i = 0; i < Object.keys(monthlytotals).length; i++) {
            let serialNumber = monthlytotals[i].serialNumber;
            if (__this.tableService.onGroundArrayFinal.hasOwnProperty(serialNumber)) {
              monthlytotals[i].daysOnGround = __this.tableService.onGroundArrayFinal[monthlytotals[i].serialNumber].daysOnGround;
            } else {
              monthlytotals[i].daysOnGround = '-';
            }
          }
          __this.tableService.tableData = monthlytotals;
          __this.tableService.monthlyTotalsLoader = false; // Hide loader
        }
      })
  }
  onSubmit() {
    let __this = this;
    console.log(__this.aircraftType);
    console.log(__this.assetType);
    console.log(__this.operator);
    console.log(__this.marketingRep);
    console.log(__this.techRep);

    if (__this.organisation === 'aircastle') {
      __this.smartTableValues = {
        assetModelSeries: __this.aircraftType,
        assetType: __this.assetType,
        operator: __this.operator,
        marketingRep: __this.marketingRep,
        techRep: __this.techRep
      }
    } else {
      __this.smartTableValues = {
        assetModelSeries: __this.aircraftType,
        assetType: __this.assetType,
        operator: __this.operator,
        // serialNumber: $('.serialNumber input').val(),
      }
    }
    __this.tableService.monthlyTotalsLoader = true; // Show loader
    this.httpClient.post(config.apiUrl + '/uniform/monthlytotalss/undefined/' + __this.startMonth + '/' + __this.endMonth, __this.smartTableValues)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let monthlytotals = response.data;
          console.log('response Data from month Component', monthlytotals);
          for (var i = 0; i < Object.keys(monthlytotals).length; i++) {
            let serialNumber = monthlytotals[i].serialNumber;
            if (__this.tableService.onGroundArrayFinal.hasOwnProperty(serialNumber)) {
              monthlytotals[i].daysOnGround = __this.tableService.onGroundArrayFinal[monthlytotals[i].serialNumber].daysOnGround;
            } else {
              monthlytotals[i].daysOnGround = '-';
            }
          }
          __this.tableService.tableData = monthlytotals;
          __this.tableService.monthlyTotalsLoader = false; // Hide loader
        }
      })
  }
}
