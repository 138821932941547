import { Component, OnInit } from '@angular/core';
import config from './../../../../configuration.json'
import { HttpClient } from '@angular/common/http';
import * as Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';
import { TableserviceService } from '../../services/tableservice.service';
import HC_exporting from 'highcharts/modules/exporting';
import { AUTO_STYLE } from '@angular/animations';
HC_exporting(Highcharts);
import { InsightServiceService } from '../../services/insight-service.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthService } from '../../auth/auth.service';


declare let $:any;

@Component({
  selector: 'app-monthly-insight',
  templateUrl: './monthly-insight.component.html',
  styleUrls: ['./monthly-insight.component.css']
})
export class MonthlyInsightComponent implements OnInit {

  dateRange: string;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public tableData:any = [];
  public msnArray:any = [];
  public fcArray:any = [];
  public fhArray:any = [];
  public fhDetailArray: any = [];
  public totalFc = 0;
  public totalFh = 0;
  public operators:any =[];
  public filterArray: any = [];
  public temp: any = [];
  public filterFlag: boolean;
  public operatorSelected: any = [];
  public assetTypeSelected: any = [];
  public flightHoursSelected: any = [];
  public flightHoursSelected1;
  public flightHoursSelected2;
  public assetModelSeriesSelected: any = [];
  public mRepSelected: any = [];
  public techRepSelected: any = [];
  public dropdownList = [];
  public selectedItems = [];
  public filterOrder = [];

  dropdownSettings:IDropdownSettings;
    constructor(
    public httpClient: HttpClient,
    public tableService: TableserviceService,
    public insightService: InsightServiceService,
    public authService: AuthService

  ) { }

  ngOnInit() {
    // result = [1,3,4,2,8]
    // console.log('Initial DropDown Box Values');
    // console.log(this.operatorSelected);
    // console.log(this.assetTypeSelected);
    // console.log(this.flightHoursSelected1);
    // console.log(this.flightHoursSelected2);
    // console.log(this.flightHoursSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);

    this.operatorSelected = [];
    this.assetModelSeriesSelected =[];
    this.assetTypeSelected = [];
    this.flightHoursSelected1 = 0;
    this.mRepSelected =[];
    this.techRepSelected = [];
    this.filterOrder = [];
    // console.log('After Reset DropDown Box Values');
    // console.log(this.operatorSelected);
    // console.log(this.assetTypeSelected);
    // console.log(this.flightHoursSelected1);
    // console.log(this.flightHoursSelected2);
    // console.log(this.flightHoursSelected)
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    let currentDate = new Date();
    this.dateRange = this.months[currentDate.getMonth()] + '-' + currentDate.getFullYear();
    let __this = this;
    // console.log('Monthly Insight Component');
    __this.insightService.initMonthlyInsight().then(() => {
      __this.insightService.fhMin = Math.min(...__this.insightService.flightHours);
      __this.insightService.fhMax = Math.max(...__this.insightService.flightHours);
      // console.log('FLIGHT HOURSSSSSS');
      // console.log(__this.insightService.flightHours);
      // console.log('MIN FH VALUE: '+__this.insightService.fhMin);
      // console.log('MAX FH VALUE: '+__this.insightService.fhMax);
      __this.flightHoursSelected1 = __this.insightService.fhMax;
      __this.flightHoursSelected2 = __this.insightService.fhMax;
    })
    __this.filterFlag = false;
    // console.log('FILTER FLAG:  ');
    // console.log(__this.filterFlag);
    // console.log(__this.insightService.fhMax);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  onDeselectAll(val: any,target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.flightHoursSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch(target) {
      case 'operator':
        this.operatorSelected = [];
        for (let i=0;i<this.filterOrder.length;i++) {
          if (this.filterOrder[i] === 'operator') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'assetType':
        this.assetTypeSelected = [];
          for (let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetType') {
              this.filterOrder.splice(i,1);
            }
          }
          this.onSelected(val, target, action);
        break;
      case 'assetModelSeries':
        this.assetModelSeriesSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'assetModelSeries') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'mRep':
        this.mRepSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'mRep') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
      case 'techRep':
        this.techRepSelected = [];
        for (let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'techRep') {
            this.filterOrder.splice(i,1);
          }
        }
        this.onSelected(val, target, action);
        break;
    }
  }

  onDeselect(val: any,target, action) {
    // console.log('Comes into Deselect');
    // console.log('Values of all Boxes before Update');
    // console.log(this.operatorSelected);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    switch(target) {
      case 'operator':
        if (!this.operatorSelected.length) {
          //deselect all -- the filter values are null
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'operator') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'assetType':
        if (!this.assetTypeSelected.length) {
          //deselect all -- the filter values are null
            for(let i=0; i<this.filterOrder.length; i++) {
              if (this.filterOrder[i] === 'assetType') {
                this.filterOrder.splice(i,1);
              }
            }
            this.onSelected(val, target, action);
          } else {
            this.onSelected(val, target, action);
          }
          break;
      case 'flightHours':
        for(let i=0; i<this.filterOrder.length; i++) {
          if (this.filterOrder[i] === 'flightHours') {
            this.filterOrder.splice(i,1);
          }
        }
        if (!this.filterOrder.length) {
          this.flightHoursSelected1 = this.flightHoursSelected2;
          this.onSelected(val, target, action);
        } else{
          this.onSelected(val, target, action);
        }
        break;
      case 'assetModelSeries':
        if (!this.assetModelSeriesSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'assetModelSeries') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'mRep':
        if (!this.mRepSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'mRep') {
              this.filterOrder.splice(i,1);
            }
          }
          this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
      case 'techRep':
        if (!this.techRepSelected.length) {
          for(let i=0; i<this.filterOrder.length; i++) {
            if (this.filterOrder[i] === 'techRep') {
              this.filterOrder.splice(i,1);
            }
          }this.onSelected(val, target, action);
        } else {
          this.onSelected(val, target, action);
        }
        break;
    }
  }

  onSelected(val: any, target, action) {
    //window.alert(this.flightHoursSelected1);
    // console.log('Values of all Boxes');
    // console.log(this.operatorSelected);
    // console.log(this.assetTypeSelected);
    // console.log(this.flightHoursSelected1);
    // console.log(this.assetModelSeriesSelected);
    // console.log(this.mRepSelected);
    // console.log(this.techRepSelected);
    let __this = this;
    if (action == 'select') {
      if (!(__this.filterOrder.indexOf(target)>=0)) {
        __this.filterOrder.push(target);
      }
      // console.log('FILTER ORDER ARRAY');
      // console.log(__this.filterOrder);
    }
    let operatorSelected = __this.operatorSelected;
    let assetTypeSelected = __this.assetTypeSelected;
    let flightHoursSelected = __this.flightHoursSelected;
    let flightHoursSelected1 = __this.flightHoursSelected1;
    let flightHoursSelected2 = __this.flightHoursSelected2;
    let assetModelSeriesSelected = __this.assetModelSeriesSelected;
    let mRepSelected = __this.mRepSelected;
    let techRepSelected = __this.techRepSelected;
    let firstFilter = __this.insightService.tableData.filter((result: any) => {
      if (operatorSelected.length) {
        let isPresent = operatorSelected.includes(result.operator);
        if (isPresent) {
          return result;
        }
      } else {
        return __this.insightService.tableData;
      }
    }).filter((result1: any) => {
      if (assetTypeSelected.length) {
        let isPresent = assetTypeSelected.includes(result1.assetType);
        if (isPresent) {
          return result1;
        }
      } else {
        return result1;
      }
    }).filter((result2: any) => {
      if ((__this.filterOrder.indexOf('flightHours')>=0)) {
        return(result2.flightHours <= flightHoursSelected1);
        // let isPresent = flightHoursSelected.includes(result2.flightHours);
        // if (isPresent) {
        //   return result2;
        // }
      } else {
        return result2;
      }
    }).filter((result3: any) => {
      if (assetModelSeriesSelected.length) {
        let isPresent = assetModelSeriesSelected.includes(result3.assetModelSeries);
        if (isPresent) {
          return result3;
        }
      }else {
        return result3;
      }
    }).filter((result4: any) => {
      if (mRepSelected.length) {
        let isPresent = mRepSelected.includes(result4.marketingRep);
        if (isPresent) {
          return result4;
        }
      } else {
        return result4;
      }
    }).filter((result5: any) => {
      if (techRepSelected.length) {
        let isPresent = techRepSelected.includes(result5.techRep);
        if (isPresent) {
          return result5;
        }
      } else {
        return result5;
      }
    });
    // console.log('Data after filters in Monthly Insight');
    // console.log(firstFilter);
    __this.insightService.arrayPrepGraph(firstFilter);
    __this.insightService.arrayPrepDropDownBox(firstFilter, this.filterOrder);
  }

  ngAfterViewInit () {
    let __this = this
    $('.datepicker').datepicker({
      format: 'M-yyyy',
      startView: 'months',
      minViewMode: 'months',
      autoclose: true,
      endDate: '+0d',
    }).datepicker('setDate', 'now')
    .change(function () {
      __this.dateRange = $(this).val();
    });
  }

  onSubmit() {
    // console.log('Date selected: ' + this.dateRange);
    let msnArr = [];
    let fhArr = [];
    let fcArr = [];
    let fhDetail = [];

    let __this = this;
    __this.tableService.monthlyTotalsLoader = true; // Show loader
    this.httpClient.get(config.apiUrl + '/uniform/monthlytotals/undefined/' + this.dateRange)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let monthlytotals = response.data;
          __this.insightService.tableData = monthlytotals;
          __this.insightService.master = monthlytotals;
          console.log("TEST monthlyTotalsTableDataArr");
          console.log(monthlytotals);
          for (let i=0; i<Object.keys(__this.insightService.tableData).length; i++) {
            msnArr.push(__this.insightService.tableData[i].serialNumber);
            let temp = {};
            temp["y"]= __this.insightService.tableData[i].flightHours;
            temp["msn"] = __this.insightService.tableData[i].serialNumber;
            temp["registration"] = __this.insightService.tableData[i].registration;
            temp["type"] = __this.insightService.tableData[i].assetModelSeries;
            temp["operator"] = __this.insightService.tableData[i].operator;
            fhDetail.push(temp);
            fhArr.push(__this.insightService.tableData[i].flightHours);
            fcArr.push(__this.insightService.tableData[i].flightCycles);
          }
          console.log("AFTER CHANGES");
          console.log(fhDetail);

          __this.fhDetailArray = fhDetail;
          __this.msnArray = msnArr;
          __this.fcArray = fcArr;
          __this.fhArray = fhArr;
          // console.log('MSN ARRAY');
          // console.log(__this.msnArray);
          // console.log('FC ARRAY');
          // console.log(__this.fcArray);
          // console.log('FH ARRAY');
          // console.log(__this.fhArray);
          __this.insightService.totalFh = (__this.fhArray.reduce(function(acc, val) { return acc + val; }, 0)).toFixed(2);
          __this.insightService.totalFc = __this.fcArray.reduce(function(acc, val) { return acc + val; }, 0);
          console.log("TOTAL FH = "+__this.totalFh);
          console.log("TOTAL FC = "+__this.totalFc);
          __this.insightService.graphUtilisation(__this.msnArray, __this.fhDetailArray, __this.fcArray);
          let operators = [];
          let assetModelSeries = [];
          let assetType = [];
          let flightHours = [];
          let mRep = [];
          let techRep =[];
          for (let i =0; i<monthlytotals.length; i++) {
            if (!(operators.indexOf(monthlytotals[i].operator)>=0)) {
              operators.push(monthlytotals[i].operator);
            }
            if (!(assetType.indexOf(monthlytotals[i].assetType)>=0)) {
              assetType.push(monthlytotals[i].assetType);
            }
            if (!(flightHours.indexOf(monthlytotals[i].flightHours)>=0)) {
              flightHours.push(monthlytotals[i].flightHours);
            }
            if (!(assetModelSeries.indexOf(monthlytotals[i].assetModelSeries)>=0)) {
              assetModelSeries.push(monthlytotals[i].assetModelSeries);
            }
            if (!(mRep.indexOf(monthlytotals[i].marketingRep)>=0)) {
              mRep.push(monthlytotals[i].marketingRep);
            }
            if (!(techRep.indexOf(monthlytotals[i].techRep)>=0)) {
              techRep.push(monthlytotals[i].techRep);
            } else{
              continue;
            }
          }
          // console.log('Month Data');
          // console.log(monthlytotals);
          __this.insightService.operators = operators.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.assetType = assetType.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.flightHours = flightHours.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.assetModelSeriesArr = assetModelSeries.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.mRepArr = mRep.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.techRepArr =techRep.sort((one, two) => (one > two ? 1 : -1));
          __this.insightService.fhMin = Math.min(...__this.insightService.flightHours);
          __this.insightService.fhMax = Math.max(...__this.insightService.flightHours);
          // console.log('FLIGHT HOURSSSSSS');
          // console.log(__this.insightService.flightHours);
          // console.log('MIN FH VALUE: '+__this.insightService.fhMin);
          // console.log('MAX FH VALUE: '+__this.insightService.fhMax);
          __this.flightHoursSelected1 = __this.insightService.fhMax;
          __this.flightHoursSelected2 = __this.insightService.fhMax;
          //__this.tableService.monthlyTotalsLoader = false; // Hide loader
        }
    });
  }
}
